import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { GeoDataTable } from "components";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import InventoryCollapseAddButtons from "components/InventoryCollapseAddButtons";
import { EditIconButton } from "components/EditIconButton";
import useAreaColumn from "components/Table/useAreaColumn";

function getDamagesWithTheme(items, themeData, themeProp) {
  if (items) {
    items.forEach((f) => {
      const theme = themeData?.find((td) => td.id === f.id);
      const themeColor = theme ? theme[themeProp] : "gray";
      f.themeColor = themeColor;
    });
  }
  return items;
}

export default function DamageListView({
  expanded,
  toggleExpanded,
  damages,
  isLoading,
  geodata,
  themeData,
  themeProp,
}) {
  const { t } = useTranslation();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const { highlightLayerFeatures } = useInventoryDispatch();
  const { rootUrl } = useOrgState();
  const areaHeader = useAreaColumn();

  useEffect(() => {
    getDamagesWithTheme(damages, themeData, themeProp);
  }, [themeData, damages, themeProp]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography component={"h3"} variant="h5">
          {t("inventory.damage.damageList")}
        </Typography>
        <InventoryCollapseAddButtons
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          addUrl={`${rootUrl}/inventory/damage/add`}
        />
      </Box>
      <GeoDataTable
        components={{
          HeaderLeft: (
            <p className="small m-0">
              {damages && damages.length ? t("common.selectToZoom") : ""}
            </p>
          ),
        }}
        style={{ minHeight: "100%" }}
        isLoading={isLoading}
        map={inventoryMap}
        ftrsClicked={ftrsClicked}
        featureCollection={geodata}
        highlightByIds={highlightLayerFeatures}
        tableProps={{ size: "sm" }}
        data={damages}
        hiddenColumns={["farm"]}
        columns={[
          {
            id: "damage-table-actions",
            accessor: "id",
            // eslint-disable-next-line react/prop-types
            Cell: function ActionCell({ value }) {
              return (
                <EditIconButton
                  size="small"
                  sx={{ m: 1 }}
                  href={`${rootUrl}/inventory/damage/${value}/edit`}
                />
              );
            },
            isSortable: false,
            disableGlobalFilter: true,
            styles: { width: "50px" },
          },
          {
            Header: t("common.field"),
            accessor: "field",
            // eslint-disable-next-line react/prop-types
            Cell: function FieldCol({ value, row }) {
              // eslint-disable-next-line react/prop-types
              const { farm } = row.original;
              return (
                <Stack>
                  <span>{value}</span>
                  <small>{farm}</small>
                </Stack>
              );
            },
          },
          areaHeader,
          // search only column
          { accessor: "farm" },
        ]}
      />
    </>
  );
}
DamageListView.defaultProps = {
  damages: null,
  isLoading: false,
  themeData: null,
  themeProp: "",
  geodata: null,
};

DamageListView.propTypes = {
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
  damages: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
  themeProp: PropTypes.string,
  geodata: PropTypes.shape({}),
};
