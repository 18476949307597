import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { FormWrapper } from "components";
import { useInventoryState } from "providers/InventoryProvider";
import { IProduct, IUrlParams } from "types";
import Autocomplete from "lib/Select/Autocomplete";
import DateRangeInputs from "components/Forms/DateRangeInputs";
import { useProductsBySeason } from "api/products/useProductsBySeason";
import { useCallback, useMemo, useState } from "react";
import { useOperationMutations } from "api/operations/useOperationMutations";
import FieldsAndSubfields from "../../../FieldsAndSubfields";
import { OperationPlantedOrAppliedPostData } from "api/operations/types/OperationPostData";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import { OperationPlanted } from "api/operations/types/OperationPlanted";
import { parseIds } from "../parseIds";

const defaultValues = {
  id: "",
  fieldIds: "",
  subfieldIds: "",
  productId: "",
  cropTypeId: "",
  beginDateUtc: "",
  endDateUtc: "",
  rate: "",
  geometry: "",
} as OperationPlantedOrAppliedPostData;

export default function PlantedOperationForm({
  getCancelHref,
}: {
  getCancelHref?: (_onDelete?: boolean) => string;
}) {
  const { t } = useTranslation();
  const { itemId } = useParams<IUrlParams>();
  const history = useHistory();
  const { org, season } = useOrgState();
  const productsQ = useProductsBySeason({ org, seasonId: season?.id });
  const { saveMutation, deleteMutation, invalidate } = useOperationMutations<
    OperationPlantedOrAppliedPostData
  >({
    org,
    type: "planted",
  });
  const { drawData } = useInventoryState();
  const geodataQ = useOperationsGeodataById<OperationPlanted>({
    id: itemId,
    org,
    seasonId: season?.id,
    type: "planted",
  });
  const currentEditFtr = geodataQ.data?.features[0];

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const {
    id,
    productId,
    subfieldIds,
    fieldIds,
    beginDateUtc,
    endDateUtc,
    rate,
  } = watch();

  const currentProd = useMemo(() => {
    return (
      productsQ.data
        ?.filter((d) => d.isSeed)
        .find(
          (ct: { cropTypeId?: string; id?: string }) =>
            ct.id === productId &&
            ct.cropTypeId === currentEditFtr?.properties?.cropTypeId
        ) ?? null
    );
  }, [currentEditFtr?.properties?.cropTypeId, productId, productsQ.data]);

  const [selectedProduct, setSelectedProduct] = useState<IProduct>();

  const handleSave = useCallback(
    async (d: OperationPlantedOrAppliedPostData) => {
      if (!formState.isDirty) {
        history.push(getCancelHref());
        return false;
      }
      const body = { ...d };
      body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
      // server doesn't want these props if not set
      if (!body.id) {
        delete body.id;
      }
      if (!body.fieldIds?.length) {
        delete body.fieldIds;
      } else {
        body.fieldIds = JSON.parse(body.fieldIds);
      }
      if (!body.subfieldIds?.length) {
        delete body.subfieldIds;
      } else {
        body.subfieldIds = JSON.parse(body.subfieldIds);
      }
      body.cropTypeId = selectedProduct?.cropTypeId || currentProd?.cropTypeId;
      return saveMutation.mutateAsync(body, {
        onSuccess: () => {
          reset(d);
          history.push(getCancelHref());
        },
      });
    },
    [
      currentProd?.cropTypeId,
      formState.isDirty,
      getCancelHref,
      history,
      reset,
      saveMutation,
      selectedProduct?.cropTypeId,
    ]
  );

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={{
        ...saveMutation,
        errorMessage: saveMutation.error,
      }}
      deleteState={{ ...deleteMutation, errorMessage: deleteMutation.error }}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        deleteMutation.mutate(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(getCancelHref(true));
            // invalidate after navigation to prevent GET of deleted item
            invalidate();
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        {!itemId ? (
          <>
            <FieldsAndSubfields
              control={control}
              ids={{
                fieldIds: parseIds(fieldIds),
                subfieldIds: parseIds(subfieldIds),
              }}
            />
          </>
        ) : null}

        <DateRangeInputs
          required
          control={control}
          setValue={setValue}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <Controller
          name="productId"
          control={control}
          rules={{ required: true }}
          htmlFor="product-select"
          render={(props: {
            onChange: (_id: string) => void;
            value: string;
          }) => (
            <Autocomplete
              id="product-select"
              label={`${t("common.product")} *`}
              groupBy={(o) => o.type}
              options={
                productsQ.data
                  ?.filter((d) => d.isSeed)
                  ?.sort((a, b) =>
                    a.type > b.type ? 1 : a.type === b.type ? 0 : -1
                  ) || []
              }
              getOptionLabel={(o: IProduct) => `${o.name}`}
              error={!!errors?.productId}
              inputProps={{ required: true, name: "productId" }}
              value={selectedProduct ?? currentProd}
              onChange={(_e, item: IProduct) => {
                setSelectedProduct(item);
                props.onChange(item?.id || "");
              }}
              disableClearable={false}
            />
          )}
        />

        <TextField
          label={`${t("common.rate")}`}
          fullWidth
          InputProps={{
            endAdornment: (
              <Typography sx={{ ml: 2 }}>
                {(selectedProduct ?? currentProd)?.rateUom}
              </Typography>
            ),
          }}
          inputProps={{
            name: "rate",
            ref: register,
            step: 0.01,
            min: 0,
          }}
          value={rate}
          required
          id="rate-input"
          type="number"
        />
      </Stack>
    </FormWrapper>
  );
}
