import { useEffect, useMemo } from "react";
import { Redirect, Route, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOrgState } from "providers/OrgProvider";
import { useSettingsState } from "providers/SettingsProvider";
import { useYieldFilters } from "./useYieldFilters";
import YieldFilterForm from "./YieldFilterForm";
import { DataTable } from "components";
import { EditIconButton } from "components/EditIconButton";
import { useCropTypes } from "api/lookups/useCropTypes";

export default function DataFilters() {
  const { rootUrl, org, season } = useOrgState();
  const cropTypesQ = useCropTypes(org);
  const { language } = useSettingsState();
  const { addOrEdit } = useParams();
  const { editId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    yieldFilters,
    saveItem,
    deleteItem,
    state: yieldFiltersState,
    saveState,
    deleteState,
    resetErrors,
    getYieldFilters,
  } = useYieldFilters({
    org,
    seasonId: season?.id,
  });
  const cropTypes = useMemo(() => {
    return addOrEdit === "add"
      ? cropTypesQ.data?.map((i) => {
          if (yieldFilters?.find((yf) => yf.cropTypeId === i.id)) {
            return { disabled: true, ...i };
          }
          return { ...i };
        })
      : cropTypesQ.data?.filter((i) =>
          yieldFilters?.find((yf) => yf.cropTypeId === i.id)
        );
  }, [addOrEdit, cropTypesQ.data, yieldFilters]);

  const editItem = yieldFilters?.find((d) => d.cropTypeId === editId);

  const columns = [
    {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row) {
        return (
          <EditIconButton
            size="small"
            sx={{ m: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `${rootUrl}/settings/filters/edit/${row.cropTypeId}`
              );
            }}
          />
        );
      },
    },
    {
      Header: t("common.cropType"),
      accessor: "crop",
    },
    {
      Header: t("common.yieldMin"),
      id: "yieldMin",
      accessor: (row) => {
        return row.yieldMin ? `${row.yieldMin} ${row.yieldUom}` : "--";
      },
    },
    {
      Header: t("common.yieldMax"),
      id: "yieldMax",
      accessor: (row) => {
        return row.yieldMax ? `${row.yieldMax} ${row.yieldUom}` : "--";
      },
    },
    {
      Header: t("common.speedMin"),
      id: "speedMin",
      accessor: (row) => {
        return row.speedMin ? `${row.speedMin} ${row.speedUom}` : "--";
      },
    },
    {
      Header: t("common.speedMax"),
      id: "speedMax",
      accessor: (row) => {
        return row.speedMax ? `${row.speedMax} ${row.speedUom}` : "--";
      },
    },
  ];

  useEffect(() => {
    getYieldFilters();
  }, [getYieldFilters, language]);

  if (!editItem && yieldFilters && editId) {
    return <Redirect to={`${rootUrl}/settings`} />;
  }
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* only show Add button on filters home view */}
        <Route path="/:org/:season/settings" exact>
          <IconButton
            sx={{ ml: "auto", "&:hover": { color: "success.main" } }}
            color="success"
            href={`${rootUrl}/settings/filters/add`}
          >
            <AddOutlinedIcon />
          </IconButton>
        </Route>
        <Route path="/:org/:season/settings/filters/:addOrEdit">
          <IconButton
            sx={{ ml: "auto", "&:hover": { color: "text.primary" } }}
            href={`${rootUrl}/settings`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Route>
      </Box>
      {/* only show data table on filters home view */}
      <Route path="/:org/:season/settings" exact>
        <Box>
          <DataTable
            tableProps={{ size: "sm" }}
            isFilterable={false}
            data={yieldFilters}
            columns={columns}
          />
        </Box>
      </Route>
      {/* show create or edit view */}
      <Route path="/:org/:season/settings/filters/:addOrEdit">
        <YieldFilterForm
          editId={editId}
          cropTypes={cropTypes}
          data={editItem}
          onSubmit={async (d) => {
            const res = await saveItem(d, editItem ? "PUT" : "POST");
            if (!res.isError) {
              history.push(`${rootUrl}/settings`);
            }
          }}
          onDelete={async (d) => {
            const res = await deleteItem(d);
            if (!res.isError) {
              history.push(`${rootUrl}/settings`);
            }
          }}
          onCancel={() => {
            resetErrors();
          }}
        />
      </Route>
      {yieldFiltersState.isError || saveState.isError || deleteState.isError ? (
        <div className="mt-3">
          {yieldFiltersState?.isError ? (
            <Alert severity="error">
              An error occurred fetching yield filters
            </Alert>
          ) : null}
          {saveState?.isError ? (
            <Alert severity="error">
              An error occurred saving yield filter.
            </Alert>
          ) : null}
          {deleteState?.isError ? (
            <Alert severity="error">
              An error occurred deleting yield filter.
            </Alert>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
