import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export default function ToggleableControlView({
  children,
  onToggle,
  isToggled,
  icon,
  location,
  title,
  sx,
}) {
  const [showContainer, setShowContainer] = useState(isToggled);
  const isTop = location?.split("-")[0] === "top";
  const isLeft = location?.split("-")[1] === "left";

  const ContainerComponent = (
    <Paper
      sx={{
        backgroundImage: "none",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#333" : "#f2f2f2",
        px: 2,
        py: 1,
        position: "relative",
        ...sx,
      }}
      className="toggleable-container"
      style={!showContainer ? { display: "none" } : null}
    >
      {children}
    </Paper>
  );

  return (
    <Box
      sx={{ display: "flex" }}
      className="toggleable-component mapboxgl-ctrl cai-mapboxgl-ctrl"
    >
      {!isLeft ? ContainerComponent : null}
      <div
        style={
          !isLeft
            ? { marginLeft: "1px", marginTop: !isTop ? "auto" : null }
            : { marginRight: "1px", marginTop: !isTop ? "auto" : null }
        }
      >
        <IconButton
          sx={{
            borderRadius: "0.25rem",
          }}
          className="ctrl-btn toggle mt-auto"
          title={title}
          onClick={() => {
            onToggle(!showContainer);
            setShowContainer(!showContainer);
          }}
        >
          {icon}
        </IconButton>
      </div>
      {isLeft ? ContainerComponent : null}
    </Box>
  );
}

ToggleableControlView.defaultProps = {
  children: null,
  onToggle: () => {},
  isToggled: true,
  icon: null,
};

ToggleableControlView.propTypes = {
  sx: PropTypes.any,
  children: PropTypes.any,
  onToggle: PropTypes.func,
  isToggled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  location: PropTypes.oneOf([
    "top-right",
    "top-left",
    "bottom-right",
    "bottom-left",
  ]).isRequired,
  title: PropTypes.string,
};
