import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useOrgState } from "providers";
import { useOperationEvents } from "api/operations/useOperationEvents";
import { OperationType } from "types/OperationType";
import DataTable from "lib/DataTable";
import { OperationEvent } from "api/operations/types/OperationEvent";
import { EditIconButton } from "components/EditIconButton";
import { useParams } from "react-router-dom";
import { IUrlParams } from "types";

export default function OperationEventsTable({
  operationId,
  type,
}: {
  operationId: string;
  type: OperationType;
}) {
  const { t } = useTranslation();
  const { itemId } = useParams<IUrlParams>();
  const { org, season } = useOrgState();

  const recordsCols = useMemo(
    () => [
      {
        Header: "",
        id: "actions",
        isSortable: false,
        accessor: function Cell(row: OperationEvent) {
          return (
            <EditIconButton size="small" href={`${itemId}/records/${row.id}`} />
          );
        },
      },
      {
        Header: t("common.date"),
        id: "dateUtc",
        accessor: (row: { id: string; dateUtc: string }) => {
          return row.dateUtc ? new Date(row.dateUtc).toLocaleDateString() : "";
        },
      },
      { Header: t("common.type"), accessor: "template" },
      { Header: t("common.photos"), accessor: "photoCount" },
    ],
    [itemId, t]
  );

  const eventsQ = useOperationEvents({
    org,
    seasonId: season?.id,
    type,
    operationId,
  });
  return (
    <Box>
      <DataTable
        isFilterable={false}
        tableProps={{ size: "sm" }}
        data={eventsQ.data || []}
        columns={recordsCols}
      />
    </Box>
  );
}
