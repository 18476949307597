import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";
import Grid from "@mui/material/Unstable_Grid2";
import { useOrgState } from "providers";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";

export default function MapThemes({ themeType, onThemeTypeChange }) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const mapThemesQ = useInventoryMapThemes(org, "fields", season?.id);
  const themeData = mapThemesQ.data?.rollup;
  return (
    <Grid container mx={2}>
      {themeData && themeData.length ? (
        <MapThemeItem
          id="landAgreementType"
          title={t("inventory.fields.landAgreement")}
          isActive={themeType === "landAgreementType"}
          onClick={() => {
            onThemeTypeChange("landAgreementType");
          }}
          data={themeData}
        />
      ) : null}
      {themeData && !themeData.length ? (
        <h6 className="text-center small mt-4">
          {t("inventory.fields.addFieldsToSeeMapThemes")}
        </h6>
      ) : null}
      {!themeData ? (
        <MapThemeItem
          id="skeleton"
          title={t("inventory.fields.landAgreement")}
          isActive={false}
          data={[{ percent: 100, name: "" }]}
          showTooltip={false}
        />
      ) : null}
    </Grid>
  );
}
MapThemes.propTypes = {
  themeType: PropTypes.oneOf(["landAgreementType", "cropType"]),
  onThemeTypeChange: PropTypes.func.isRequired,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
};
