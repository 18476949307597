import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { AlertCircle } from "react-feather";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { FeatureCollection } from "geojson";
import { GeoDataTable } from "components";
import BaseForm from "components/Forms/BaseForm";
import {
  useInventoryDispatch,
  useOrgState,
  useAssetsState,
  useInventoryState,
} from "providers";
import { IFixedAssetType } from "types/IAssetType";
import GeodataUpload from "views/InventoryView/GeodataUpload";
import { ISelectOption } from "types";
import { setFeatureCollectionProps } from "app-utils";
import { useAssetsUpload } from "api/useAssetsUpload";
import { ManualSwitchFormField } from "./FormFields";
import { validateFeatureCounts } from "./asset-upload-utils";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import Autocomplete from "lib/Select/Autocomplete";
import { DatePicker } from "lib/DatePicker";

export default function FixedAssetUpload({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>();
  const fileUploadRef = useRef<HTMLInputElement>();
  const { fixedAssetTypes } = useAssetsState() as {
    fixedAssetTypes: IFixedAssetType[];
  };

  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const {
    highlightLayerFeatures,
    fitInventoryBounds,
    setInventoryFtrs,
  } = useInventoryDispatch();
  const { ftrsClicked, inventoryMap } = useInventoryState();
  const [fixedAssetTypeId, setFixedAssetTypeId] = useState("");
  const [assetStatusId, setAssetStatusId] = useState("");
  const [parseError, setParseError] = useState("");
  const [parsedKeys, setParsedKeys] = useState<ISelectOption[]>([]);
  const [uploadedFc, setUploadedFc] = useState<FeatureCollection>();
  // prop mappings
  const [cidProp, setCidProp] = useState("");
  const [bornOnProp, setBornOnProp] = useState("");
  const [nameProp, setNameProp] = useState("");
  const [descriptionProp, setDescriptionProp] = useState("");
  // manual inputs
  const [manualDate, setManualDate] = useState<Date>();
  const [manualDescription, setManualDescription] = useState("");
  const [manualName, setManualName] = useState("");

  const { uploadAssets, ...query } = useAssetsUpload({
    org,
    seasonId: season.id,
    type: "fixed",
  });

  function reset() {
    setParsedKeys([]);
    setParseError("");
    setCidProp("");
    setNameProp("");
    setBornOnProp("");
    setDescriptionProp("");
    setManualDescription("");
    setManualDate(null);
    setManualName("");
  }

  const assetType = fixedAssetTypes?.find(
    (ct) => ct.value === fixedAssetTypeId
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cid",
        Cell: ({ value }: { value: string | number }) => {
          return value && typeof value === "object"
            ? JSON.stringify(value)
            : value;
        },
      },
      {
        Header: `${t("common.name")} *`,
        accessor: "name",
        Cell: ({ value }: { value: string | number }) => {
          const val =
            value && typeof value === "object" ? JSON.stringify(value) : value;
          return (
            <Typography color={!val ? "error.main" : undefined}>
              {value && typeof value === "object"
                ? JSON.stringify(value)
                : value ?? (
                    <>
                      <AlertCircle />
                    </>
                  )}
            </Typography>
          );
        },
      },
      {
        Header: `${t("inventory.assets.createdDate")} *`,
        accessor: "bornOnUtc",
        Cell: ({ value }: { value: string }) => {
          const val = manualDate
            ? manualDate.toLocaleDateString()
            : value && typeof value === "object"
            ? JSON.stringify(value)
            : value;
          return (
            <Typography color={!val ? "error.main" : undefined}>
              {val || (
                <>
                  <AlertCircle />
                </>
              )}
            </Typography>
          );
        },
      },
      {
        Header: t("common.notes"),
        accessor: "description",
        Cell: ({ value }: { value: unknown }) => {
          return (
            <div>
              {value && typeof value === "object"
                ? JSON.stringify(value)
                : value ?? manualDescription}
            </div>
          );
        },
      },
    ],
    [manualDescription, manualDate, t]
  );

  const formattedFC = React.useMemo(() => {
    if (!uploadedFc) {
      return null;
    }
    const formatted = setFeatureCollectionProps(
      uploadedFc,
      {
        fixedAssetTypeId,
        assetStatusId,
        ...(manualDate
          ? {
              bornOnUtc: manualDate.toISOString(),
            }
          : {}),
        ...(manualDescription ? { description: manualDescription } : {}),
        ...(manualName ? { name: manualName } : {}),
      },
      {
        name: nameProp,
        description: descriptionProp,
        bornOnUtc: bornOnProp,
        cid: cidProp,
      }
    );
    return formatted;
  }, [
    uploadedFc,
    fixedAssetTypeId,
    assetStatusId,
    manualDate,
    manualDescription,
    manualName,
    nameProp,
    descriptionProp,
    bornOnProp,
    cidProp,
  ]);

  const tableData = React.useMemo(
    () =>
      formattedFC?.features.map((f) => ({ id: f.id, ...f.properties })) ?? [],
    [formattedFC?.features]
  );

  const { invalidCount, validCount } = validateFeatureCounts(formattedFC, [
    "name",
    "bornOnUtc",
  ]);

  return (
    <>
      <BaseForm
        ref={formRef}
        onSubmit={() => {
          if (!uploadedFc) {
            setParseError(t("common.requiredField"));
            return false;
          }
          if (invalidCount) {
            return false;
          }
          return uploadAssets(formattedFC, {
            onSuccess: () => {
              onSuccess();
              history.push("./");
            },
          });
        }}
        cancelHref="./"
        saveState={{
          isLoading: query.isLoading,
          isError: query.isError,
          errorMessage: query.error?.message,
        }}
      >
        <Stack spacing={2}>
          <Autocomplete
            label={`${t("common.type")} *`}
            name={"fixedAssetTypeId"}
            options={fixedAssetTypes || []}
            value={
              fixedAssetTypes?.find((ct) => ct.value === fixedAssetTypeId) ??
              null
            }
            onChange={(_e, item: IFixedAssetType) => {
              if (fixedAssetTypeId) {
                fileUploadRef.current.value = "";
                reset();
                setUploadedFc(null);
                setInventoryFtrs(null);
              }
              setFixedAssetTypeId(item?.id ?? "");
            }}
          />
          <Autocomplete
            label={`${t("common.status")} *`}
            disableClearable={false}
            InputProps={{ required: true }}
            name="assetStatusId"
            options={assetStatuses}
            value={
              assetStatuses?.find((ao) => ao.value === assetStatusId) ?? null
            }
            onChange={(_e, item: { value: string }) => {
              setAssetStatusId(item?.value);
            }}
          />
          {fixedAssetTypeId ? (
            <>
              <Stack direction={"row"} alignItems={"center"}>
                <GeodataUpload
                  ref={fileUploadRef}
                  type={
                    assetType?.geometryType === "point"
                      ? "Point"
                      : assetType?.geometryType === "line"
                      ? "LineString"
                      : "Polygon"
                  }
                  onChange={(geojson, keys) => {
                    reset();
                    setUploadedFc(geojson);
                    if (geojson) {
                      const ftrsWithColor = setFeatureCollectionProps(geojson, {
                        color: assetType?.color || "#eee",
                        fixedAssetType: assetType?.name ?? "??",
                      });
                      setInventoryFtrs(ftrsWithColor);
                      fitInventoryBounds({ geojson });
                      setParsedKeys(
                        keys.map((p) => ({
                          value: p,
                          label: p,
                        }))
                      );
                    } else {
                      setInventoryFtrs(null);
                    }
                  }}
                  onError={(e) => {
                    setParseError(e);
                  }}
                />
                <Typography variant="body2" color="info.main">
                  GeoJSON
                </Typography>
              </Stack>

              {parseError ? (
                <Typography color="error.main">{parseError}</Typography>
              ) : null}
              {uploadedFc ? (
                <>
                  <Autocomplete
                    label="ID"
                    disableClearable={false}
                    value={
                      cidProp
                        ? parsedKeys?.find((p) => p.value === cidProp)
                        : null
                    }
                    options={parsedKeys}
                    onChange={(_e, item: { value: string }) => {
                      setCidProp(item?.value);
                    }}
                  />

                  <ManualSwitchFormField
                    id="manual-name"
                    onChange={(e) => {
                      if (!e.currentTarget.checked) {
                        setManualName("");
                      } else {
                        setNameProp("");
                      }
                    }}
                    manualInput={
                      <TextField
                        fullWidth
                        label={`${t("common.name")}`}
                        required
                        inputProps={{
                          name: "name",
                          maxLength: 50,
                        }}
                        onChange={(e) => {
                          setManualName(e.target.value);
                        }}
                      />
                    }
                  >
                    <Autocomplete
                      label={`${t("common.name")} *`}
                      disableClearable={false}
                      InputProps={{ required: true }}
                      value={
                        nameProp
                          ? parsedKeys?.find((p) => p.value === nameProp)
                          : null
                      }
                      options={parsedKeys}
                      onChange={(_e, item: { value: string }) => {
                        setNameProp(item?.value);
                      }}
                    />
                  </ManualSwitchFormField>
                  <ManualSwitchFormField
                    id="born-on-date"
                    onChange={(e) => {
                      if (!e.currentTarget.checked) {
                        setManualDate(null);
                      } else {
                        setBornOnProp("");
                      }
                    }}
                    manualInput={
                      <DatePicker
                        label={`${t("inventory.assets.createdDate")}`}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            required: true,
                          },
                        }}
                        maxDate={new Date()}
                        value={manualDate}
                        onChange={(d: Date) => {
                          setManualDate(d);
                        }}
                      />
                    }
                  >
                    <Autocomplete
                      label={`${t("inventory.assets.createdDate")} *`}
                      InputProps={{ required: true }}
                      disableClearable={false}
                      value={
                        bornOnProp
                          ? parsedKeys?.find((p) => p.value === bornOnProp)
                          : null
                      }
                      options={parsedKeys}
                      onChange={(_e, item: { value: string }) => {
                        setBornOnProp(item?.value);
                      }}
                    />
                  </ManualSwitchFormField>
                  <ManualSwitchFormField
                    id="manual-description"
                    onChange={(e) => {
                      if (!e.currentTarget.checked) {
                        setManualDescription(null);
                      } else {
                        setDescriptionProp("");
                      }
                    }}
                    manualInput={
                      <TextField
                        fullWidth
                        label={t("common.notes")}
                        multiline
                        inputProps={{ maxLength: 255 }}
                        onChange={(e) => {
                          setManualDescription(e.target.value);
                        }}
                      />
                    }
                  >
                    <Autocomplete
                      label={t("common.notes")}
                      disableClearable={false}
                      value={
                        parsedKeys?.find((p) => p.value === descriptionProp) ??
                        null
                      }
                      options={parsedKeys}
                      onChange={(_e, item: { value: string }) => {
                        setDescriptionProp(item?.value);
                      }}
                    />
                  </ManualSwitchFormField>
                </>
              ) : null}
            </>
          ) : null}
          {formattedFC ? (
            <Typography
              variant="body2"
              color={invalidCount ? "error.main" : "info.main"}
            >
              {`${t("common.validFeatures")}: ${validCount} /
                  ${formattedFC.features.length}`}
            </Typography>
          ) : null}
        </Stack>
      </BaseForm>
      {formattedFC ? (
        <div
          className="inventory-list overflow-auto"
          style={{ minHeight: "250px" }}
        >
          <GeoDataTable
            isFilterable={false}
            highlightByIds={highlightLayerFeatures}
            ftrsClicked={ftrsClicked}
            map={inventoryMap}
            featureCollection={formattedFC}
            data={tableData}
            columns={columns}
          />
        </div>
      ) : null}
    </>
  );
}
