import { useEffect } from "react";
import {
  Route,
  Switch,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { usePrevious } from "components";
import { useOrgState } from "providers/OrgProvider";
import { AssetsByTypeTable } from "./AssetsByTypeTable";
import { AssetsBySubtypeTable } from "./AssetsBySubtypeTable";
import { AssetsBySubtypeAndFieldAndGroupTable } from "./AssetsBySubtypeAndFieldAndGroupTable";
import InventoryCollapseAddButtons from "components/InventoryCollapseAddButtons";
import AssetsBreadcrumbs from "./AssetsBreadcrumbs";
import { IUrlParams } from "types";

export default function AssetsListView({
  expanded,
  toggleExpanded,
}: {
  expanded: boolean;
  toggleExpanded: () => void;
}) {
  const history = useHistory();
  const location = useLocation();
  const isAdd = location.pathname.includes("/add");
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records");
  const { rootUrl, seasonId } = useOrgState();
  const previousSeasonId = usePrevious(seasonId);
  const { subtype, itemId, type } = useParams<IUrlParams>();

  // if season changes, redirect back to asset by type summary
  useEffect(() => {
    if (seasonId !== previousSeasonId && previousSeasonId) {
      history.replace({
        pathname: `${rootUrl}/inventory/assets/${type}`,
      });
    }
  }, [seasonId, type, rootUrl, history, previousSeasonId]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "auto",
        pb: 3,
      }}
    >
      <Stack direction={"row"} sx={{ p: 3, pb: 2, alignItems: "center" }}>
        <AssetsBreadcrumbs />
        <InventoryCollapseAddButtons
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          addUrl={`${rootUrl}/inventory/assets/${type}/add`}
          hideAdd={!(type && type !== "equipment" && !subtype && !isAdd)}
          hideExpand={!(!itemId && !isEdit && !isAdd)}
        />
      </Stack>
      <Box sx={{ px: 3, flex: 1, overflow: "auto" }}>
        <Switch>
          <Route path="/:org/:season/inventory/assets/:type?" exact>
            <AssetsByTypeTable />
          </Route>
          <Route
            path="/:org/:season/inventory/assets/:type/:subtype/:fieldId/items"
            exact
          >
            <div className="inventory-list overflow-auto">
              <AssetsBySubtypeAndFieldAndGroupTable />
            </div>
          </Route>
          <Route path="/:org/:season/inventory/assets/:type/:subtype/:fieldId?">
            <AssetsBySubtypeTable />
          </Route>
        </Switch>
      </Box>
    </Paper>
  );
}
