import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { IUrlParams } from "types";
import { useAssetPhotos } from "api/assets/useAssetPhotos";
import { useAssetPhotoPost } from "api/assets/useAssetPhotoPost";
import { useAssetPhotoDelete } from "api/assets/useAssetPhotoDelete";
import ImageStack from "components/ImageStack";

function AssetPhotosStack({
  readonly,
  type,
}: {
  readonly?: boolean;
  type: "asset" | "event";
}) {
  const { org, itemId: assetId, recordId } = useParams<IUrlParams>();
  const { t } = useTranslation();
  const photosQ = useAssetPhotos({
    org,
    type,
    ...(recordId ? { eventId: recordId } : { assetId }),
  });
  const savePhotoQ = useAssetPhotoPost({
    org,
    ...(recordId ? { eventId: recordId } : { assetId }),
  });
  const deleteMutation = useAssetPhotoDelete({
    org,
    ...(recordId ? { eventId: recordId } : { assetId }),
  });

  return (
    <Box>
      {readonly !== true ? (
        <h6 className="text-uppercase">{t("common.photos")} (Max 4)</h6>
      ) : null}
      <ImageStack
        readonly={readonly}
        deletePhotoQ={deleteMutation}
        savePhotoQ={savePhotoQ}
        photosQ={photosQ}
      />
    </Box>
  );
}

export default AssetPhotosStack;
