import { useEffect } from "react";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useAssetColumns } from "components/useAssetColumns";
import { GeoDataTable, useURLSearchParams } from "components";
import { useAssetsState } from "providers/AssetsProvider";
import { useAssetsByFieldAndGroup } from "api/assets/useAssetSummaries";
import { useOrgState } from "providers";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useAssetGeodataByQuery } from "api/assets/useAssetGeodataByQuery";

export function AssetsBySubtypeAndFieldAndGroupTable() {
  const { t } = useTranslation();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const {
    setInventoryFtrs,
    highlightLayerFeatures,
    fitInventoryBounds,
  } = useInventoryDispatch();

  const { subtypeId, subtypeName, fieldName, groupName } = useAssetsState();

  const { subtype, fieldId, type: activeType } = useParams();
  const { org, season } = useOrgState();
  const query = useURLSearchParams();
  const groupId = query.get("groupId");
  const geodataQ = useAssetGeodataByQuery({
    org,
    type: activeType,
    subtypeId,
    params: {
      ...(fieldId && fieldId !== "null" ? { fieldId } : {}),
      ...(groupId ? { assetGroupId: groupId } : {}),
    },
    seasonId: season?.id,
  });
  const { subtypeAndFieldItems } = useAssetColumns();
  const dict = {
    plant: subtypeAndFieldItems.plant,
    fixed: subtypeAndFieldItems.fixed,
    equipment: subtypeAndFieldItems.equipment,
    animal: subtypeAndFieldItems.animal,
  };
  const fieldAndGroupQuery = useAssetsByFieldAndGroup(
    org,
    activeType,
    subtypeId,
    fieldId,
    groupId
  );

  const totals = fieldAndGroupQuery.data?.reduce(
    (item, next) => {
      const total =
        next.geometryType === "line"
          ? item.count + next.length
          : next.geometryType === "polygon"
          ? item.count + next.area
          : item.count + 1;
      const uom =
        next.geometryType === "line"
          ? next.lengthUom
          : next.geometryType === "polygon"
          ? next.areaUom
          : "";
      const label =
        next.geometryType === "line"
          ? t("common.totalLength")
          : next.geometryType === "polygon"
          ? t("common.totalArea")
          : t("common.totalCount");
      return { count: total, uom, label };
    },
    { count: 0, uom: "" }
  );
  const actionsColumn = {
    Header: "",
    id: "actions",
    styles: {
      width: "50px",
    },
    isSortable: false,
    accessor: function Cell(row) {
      return (
        <IconButton
          href={`./items/${row.id}${
            row.assetGroupId ? `?groupId=${row.assetGroupId}` : ""
          }`}
        >
          <InfoOutlined />
        </IconButton>
      );
    },
  };

  const summaryColumn = {
    Header: `${t("common.area")} / ${t("common.distance")}`,
    accessor: (row) => {
      let uom = "";
      if (row.geometryType === "polygon") {
        uom = `${row.area} ${row.areaUom}`;
      } else if (row.geometryType === "line") {
        uom = `${row.length} ${row.lengthUom}`;
      }
      return uom;
    },
    disableGlobalFilter: true,
  };

  const getColumnsBySubtype = (st) => {
    const cols = [actionsColumn].concat(dict[st] || []);
    const showSummary = st === "fixed";
    if (showSummary) {
      cols.push(summaryColumn);
    }
    return cols;
  };

  useEffect(() => {
    if (geodataQ.data) {
      const fc = { ...geodataQ.data };
      fc.features.filter((f) => f.geometry);
      fitInventoryBounds({ bbox: geodataQ?.data?.bbox });
      setInventoryFtrs(fc);
    }
    return () => {
      setInventoryFtrs(null);
    };
  }, [fitInventoryBounds, geodataQ.data, setInventoryFtrs]);

  return (
    <GeoDataTable
      filterPlaceholder={`${
        activeType === "fixed"
          ? t("inventory.assets.searchName")
          : t("inventory.assets.searchId")
      }...`}
      highlightByIds={highlightLayerFeatures}
      ftrsClicked={ftrsClicked}
      map={inventoryMap}
      featureCollection={geodataQ?.data}
      isLoading={fieldAndGroupQuery.isLoading}
      sortBy={[
        {
          id: "name",
          desc: false,
        },
      ]}
      components={{
        HeaderLeft: (
          <div className="d-flex flex-grow-1">
            <dl className="d-flex flex-grow-1">
              <div className="text-center mx-3">
                <dt>
                  <u>{t("common.field")}</u>
                </dt>
                <dd>
                  {fieldName ? (
                    fieldName
                  ) : !fieldAndGroupQuery.isLoading ? (
                    <span className="text-danger">
                      {t("common.invalidField")}
                    </span>
                  ) : null}
                </dd>
              </div>
              {subtype ? (
                <div className="text-center mx-3">
                  <dt>
                    <u>{t("common.type")}</u>
                  </dt>
                  <dd>
                    {subtypeName === decodeURIComponent(subtype) ? (
                      subtypeName
                    ) : !fieldAndGroupQuery.isLoading ? (
                      <span className="text-danger">
                        {t("common.invalidType")}
                      </span>
                    ) : null}
                  </dd>
                </div>
              ) : null}
              {groupId && groupName ? (
                <div className="text-center mx-3">
                  <dt>
                    <u>{t("common.group")}</u>
                  </dt>
                  <dd>
                    {groupName ? (
                      groupName
                    ) : !fieldAndGroupQuery.isLoading ? (
                      <span className="text-danger">
                        {t("common.invalidGroup")}
                      </span>
                    ) : null}
                  </dd>
                </div>
              ) : null}
              {totals ? (
                <div className="text-center mx-3">
                  <dt>
                    <u>{totals.label}</u>
                  </dt>
                  {/* show 2 decimals unless integer */}
                  <dd>{`${totals.count?.toFixed(2)?.replace(/[.,]00$/, "")} ${
                    totals.uom
                  }`}</dd>
                </div>
              ) : null}
            </dl>
          </div>
        ),
      }}
      data={fieldAndGroupQuery.data || []}
      columns={getColumnsBySubtype(activeType)}
      paging={fieldAndGroupQuery.data?.length > 200}
    />
  );
}
