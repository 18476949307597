import React, { useCallback, useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import {
  GeoDataTable,
  useTableColumns,
  useSetURLSearchParams,
  useSetAppDataLoading,
  useURLSearchParams,
} from "components";
import { useOrgState, useOrgDispatch } from "providers/OrgProvider";
import { useParams } from "react-router-dom";
import { Image } from "react-feather";
import { ScoutingInputsCell } from "./ScoutingInputsCell";
import { ConfirmationDialog } from "lib";
import { fitBounds } from "components/Map";
import Autocomplete from "lib/Select/Autocomplete";
import { useObservationsDisplay } from "api/observations/useObservationsDisplay";
import { Observation } from "types/Observation";
import { useObservationDelete } from "api/observations/useObservationDelete";
import { useObservationsGeodata } from "api/observations/useObservationsGeodata";
import { IUrlParams } from "types";
import { ObservationPhotos } from "./ObservationPhotos";
import { useObservationById } from "api/observations/useObservationById";
import { featureCollection } from "@turf/helpers";

function getExpandedRowsObj(ids: string[]) {
  const obj: Record<string, unknown> = {};
  ids.forEach((id) => {
    obj[id] = true;
  });
  return obj;
}

export function ScoutingView() {
  const { t } = useTranslation();
  const { org, rootUrl, season } = useOrgState();
  const { dispatch } = useOrgDispatch();
  const { observationId } = useParams<IUrlParams>();
  const { setInventoryFtrs, highlightLayerFeatures } = useInventoryDispatch();
  const query = useURLSearchParams();
  const fieldNames = query.get("fields");
  const setQuery = useSetURLSearchParams();
  const templateName = query.get("template");
  const { deleteObservation, isLoading: isDeleting } = useObservationDelete({
    org,
  });

  const [geodataIds, setGeodataIds] = useState<{
    fieldIds: string[];
    templateId: string;
  }>();
  const { inventoryMap, ftrsClicked } = useInventoryState();

  const observationsQ = useObservationsDisplay({
    org,
    startDateUtc: new Date(season.beginOnUtc).toISOString(),
    endDateUtc: new Date(season.endOnUtc).toISOString(),
    template: templateName,
    fieldNames,
  });
  const observationQ = useObservationById({ org, observationId });

  const { fieldHeader } = useTableColumns();
  const [expandedRows, setExpandedRows] = useState<Record<string, unknown>>();
  const [confirmObj, setConfirmObj] = useState<{
    target: HTMLElement;
    id: string;
  }>();
  const observationToDelete = useRef<string>();
  const [selectedTemplate, setSelectedTemplate] = useState<{
    id: string;
    label: string;
  }>();
  const currentTemplate =
    selectedTemplate ?? observationsQ.templates?.length
      ? observationsQ.templates.find((tmp) => tmp.label === templateName) ??
        observationsQ.templates[0]
      : null;
  const geodataQ = useObservationsGeodata({
    org,
    fieldIds: geodataIds?.fieldIds,
    templateId: currentTemplate?.id,
    observations: observationsQ.data,
    enabled: !!geodataIds?.fieldIds?.length,
  });
  useSetAppDataLoading({ dispatch, isFetchingData: observationsQ.isFetching });

  const handleRowContract = useCallback(
    (row: {
      id: string;
      leafRows: {
        original: Observation;
      }[];
    }) => {
      const curr = Object.keys(expandedRows || {});
      const ids = curr?.filter((r) => r !== row.id);
      const fieldId = row.leafRows[0]
        ? row.leafRows[0].original?.fieldId
        : null;
      setExpandedRows(getExpandedRowsObj(ids));
      setGeodataIds({
        ...geodataIds,
        fieldIds: geodataIds.fieldIds.filter((f) => f !== fieldId),
      });
    },
    [expandedRows, geodataIds]
  );

  const handleRowExpand = useCallback(
    async (row: {
      id: string;
      leafRows: {
        original: Observation;
      }[];
    }) => {
      // const ids = Object.keys(expandedRows || {}).concat([row.id]);
      setExpandedRows(getExpandedRowsObj([row.id]));
      const templateId = row.leafRows[0]
        ? row.leafRows[0].original?.templateId
        : null;
      const fieldId = row.leafRows[0]
        ? row.leafRows[0].original?.fieldId
        : null;
      setGeodataIds({
        templateId,
        fieldIds: (geodataIds?.fieldIds ?? []).concat([fieldId]),
      });
    },
    [geodataIds?.fieldIds]
  );

  const ActionCell = useCallback(
    ({ row }) => {
      const id = row?.original?.id;
      return (
        <Stack direction={"row"} spacing={1}>
          <IconButton
            href={`${rootUrl}/inventory/scouting/${row.original?.id}/photos`}
            title={t("common.photos")}
          >
            <Image />
          </IconButton>
          <IconButton
            color="error"
            // className="btn-outline-danger"
            // icon={observationToDelete.current === id ? "loader" : "delete"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              if (isDeleting) {
                // prevent delete while another still in progress
                return;
              }
              setConfirmObj({ target: e.currentTarget, id });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      );
    },
    [isDeleting, rootUrl, t]
  );

  // update inventory features on change
  useEffect(() => {
    setInventoryFtrs(
      observationQ.data ? featureCollection([observationQ.data]) : geodataQ.data
    );
  }, [geodataQ.data, observationQ.data, setInventoryFtrs]);

  // zoom to data on change
  useEffect(() => {
    if ((observationQ.data || geodataQ.data) && inventoryMap) {
      fitBounds({
        map: inventoryMap,
        geojson: observationQ.data,
        bbox: !observationQ.data ? geodataQ.data?.bbox : undefined,
        options: { animate: false },
      });
    }
  }, [geodataQ.data, inventoryMap, observationQ.data]);

  // reset inventory features when leaving
  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return (
    <Paper
      sx={{ p: 3, display: "flex", flexDirection: "column", height: "100%" }}
    >
      {observationId ? (
        <ObservationPhotos />
      ) : (
        <>
          <dl>
            <dt style={{ textTransform: "uppercase", fontWeight: "normal" }}>
              {t("common.season")}
            </dt>
            <dd>
              {season ? (
                <>
                  {season.name}
                  <span className="small">
                    {" ("}
                    {new Date(season.beginOnUtc).toLocaleDateString()} -{" "}
                    {new Date(season.endOnUtc).toLocaleDateString()}
                    {")"}
                  </span>
                </>
              ) : null}
            </dd>
          </dl>
          <GeoDataTable
            highlightByIds={highlightLayerFeatures}
            featureCollection={geodataQ.data}
            map={inventoryMap}
            ftrsClicked={ftrsClicked}
            expanded={expandedRows}
            paging={
              !!(observationsQ.observationsByTemplateAndField?.length > 100)
            }
            components={{
              HeaderLeft: (
                <div className="d-flex">
                  <Autocomplete
                    label={`${t("common.template")}`}
                    sx={{ minWidth: 200 }}
                    id="template-select"
                    name="templates"
                    value={currentTemplate}
                    options={observationsQ.templates}
                    onChange={(_e, item: { id: string; label: string }) => {
                      setSelectedTemplate(item);
                      setQuery([
                        ["template", item?.label],
                        ["fields", ""],
                      ]);
                    }}
                  />
                  <Autocomplete
                    sx={{ ml: 2, minWidth: 200 }}
                    multiple
                    disableClearable={false}
                    id="field-select"
                    name="fieldId"
                    label={`${t("common.field")}`}
                    value={
                      observationsQ.fields?.filter((fld) =>
                        fieldNames?.includes(fld?.label)
                      ) ?? null
                    }
                    options={observationsQ.fields || []}
                    onChange={(_e, items: { id: string; label: string }[]) => {
                      const q = items?.map((i) => i.label);
                      setQuery([["fields", q?.join(",") || ""]]);
                    }}
                  />
                </div>
              ),
            }}
            hiddenColumns={["notes", "template"]}
            columns={[
              {
                accessor: "template",
                Header: "Template",
              },
              {
                Header: "",
                isSortable: false,
                // NOTE: using this accessor so we can access photoCount in ScoutingInputsCell
                accessor: "photoCount",
                Cell: ActionCell,
              },
              fieldHeader,
              {
                accessor: "observedOnUtc",
                Header: t("common.date"),
                Cell: ({ value }: { value: string }) => {
                  return new Date(value).toLocaleDateString();
                },
              },
              {
                Header: t("common.inputs"),
                accessor: "inputs",
                isSortable: false,
                Cell: ScoutingInputsCell,
              },
              {
                accessor: "notes",
                Header: t("common.notes"),
              },
            ]}
            data={observationsQ.observationsByTemplateAndField}
            isLoading={observationsQ?.isLoading}
            groupBy={["field"]}
            onRowContract={handleRowContract}
            onRowExpand={handleRowExpand}
          />
          <ConfirmationDialog
            open={Boolean(confirmObj?.id)}
            message={
              <>{`${t("common.areYouSure")} ${t("common.cannotUndo")}`} </>
            }
            onClose={(confirmed) => {
              if (confirmed) {
                observationToDelete.current = confirmObj?.id;
                setConfirmObj(null);
                if (confirmObj?.id) {
                  deleteObservation(confirmObj?.id);
                }
              } else {
                observationToDelete.current = null;
                setConfirmObj(null);
              }
            }}
          />
        </>
      )}
    </Paper>
  );
}
