import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable from "lib/DataTable";
import { useOrgState } from "providers/OrgProvider";
import { ChevronRight } from "react-feather";
import { useAssetColumns } from "components/useAssetColumns";
import { useAssetsState } from "providers/AssetsProvider";
import {
  IAssetSubtypeSummaryItem,
  useAssetsBySubtypeSummary,
  useAssetsByTypeSummary,
} from "api/assets/useAssetSummaries";
import { IUrlParams } from "types";
import { IBaseAsset } from "types/IAssetType";
import { useEffect } from "react";
import { useInventoryDispatch } from "providers";

export function AssetsBySubtypeTable() {
  const { org, season, rootUrl } = useOrgState();
  const { subtype, type } = useParams<IUrlParams>();
  const { setInventoryFtrs } = useInventoryDispatch();
  const { subtypeId } = useAssetsState();
  const assetsByTypeQuery = useAssetsByTypeSummary(org, type);
  const assetsBySubtypeQuery = useAssetsBySubtypeSummary(
    org,
    type,
    subtypeId,
    season?.id
  );
  const { t } = useTranslation();
  const { subtypeAndFieldGroup } = useAssetColumns();
  const subtypeItem = assetsByTypeQuery?.data?.find(
    (d: IAssetSubtypeSummaryItem) => d.subtype === decodeURIComponent(subtype)
  );
  const dict = {
    plant: subtypeAndFieldGroup.plant,
    fixed: subtypeAndFieldGroup.fixed,
    equipment: subtypeAndFieldGroup.equipment,
    animal: subtypeAndFieldGroup.animal,
  } as {
    // TODO:
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [index: string]: any;
  };
  const actionsColumn = {
    Header: "",
    id: "actions",
    styles: {
      width: "50px",
    },
    isSortable: false,
    accessor: function Cell(row: IBaseAsset) {
      return (
        <Link
          className="btn btn-link btn-sm float-left"
          style={{ color: "inherit" }}
          to={`${rootUrl}/inventory/assets/${type}/${subtype}/${
            row.fieldId
          }/items${row.assetGroupId ? `?groupId=${row.assetGroupId}` : ""}`}
        >
          <span className="row-expander">
            <ChevronRight />
          </span>
        </Link>
      );
    },
  };

  const getColumnsBySubtype = (st: string) => {
    return [actionsColumn].concat(dict[st] || []);
  };

  useEffect(() => {
    // no geodata at this level
    setInventoryFtrs(null);
  }, [setInventoryFtrs]);

  return (
    <div className="overflow-auto">
      <DataTable
        filterPlaceholder={`${t("inventory.assets.searchField")}...`}
        isLoading={assetsBySubtypeQuery.isLoading}
        data={assetsBySubtypeQuery?.data || []}
        columns={getColumnsBySubtype(type)}
        hiddenColumns={["assetGroup"]}
        // paging
        components={{
          HeaderLeft: (
            <>
              {!subtypeItem &&
              !assetsBySubtypeQuery.isLoading &&
              !assetsByTypeQuery?.isLoading ? (
                <div className="text-danger">{t("common.invalidType")}</div>
              ) : null}
            </>
          ),
        }}
      />
    </div>
  );
}
