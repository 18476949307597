import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import SummaryCardView from "components/SummaryCardView";
import { useFieldsSummary } from "api/fields/useFieldsSummary";

export default function FieldsSummaryView() {
  const { org, season } = useOrgState();
  const fieldsSummaryQ = useFieldsSummary(org, season?.id);

  const { t } = useTranslation();

  const count =
    fieldsSummaryQ.data?.fieldCount || fieldsSummaryQ.data?.fieldCount === 0
      ? fieldsSummaryQ.data?.fieldCount
      : "--";
  const area =
    fieldsSummaryQ.data?.totalArea || fieldsSummaryQ.data?.totalArea === 0
      ? fieldsSummaryQ.data?.totalArea
      : "--";

  return (
    <>
      <Grid sx={{ m: 2 }} container spacing={2}>
        <Grid xs={12} sm={6}>
          <SummaryCardView
            value={
              fieldsSummaryQ.isLoading ? <Skeleton /> : count?.toLocaleString()
            }
            title={
              fieldsSummaryQ.isLoading ? (
                <Skeleton />
              ) : (
                t("inventory.fields.totalFields")
              )
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <SummaryCardView
            value={
              fieldsSummaryQ.isLoading ? <Skeleton /> : area?.toLocaleString()
            }
            title={
              fieldsSummaryQ.isLoading ? (
                <Skeleton />
              ) : (
                fieldsSummaryQ.data?.totalAreaUom || t("common.area")
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
