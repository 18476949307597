import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import SummaryCardView from "components/SummaryCardView";
import useSubfieldsSummary from "api/useSubfieldsSummary";

export default function SubfieldsSummaryView() {
  const { org, season } = useOrgState();
  const query = useSubfieldsSummary(org, season?.id);
  const { t } = useTranslation();
  return (
    <>
      <Grid sx={{ m: 2 }} container spacing={2}>
        <Grid xs={12} sm={6}>
          <SummaryCardView
            value={
              query.isLoading ? (
                <Skeleton />
              ) : (
                query.data?.totalCount?.toLocaleString() || "--"
              )
            }
            title={query.isLoading ? <Skeleton /> : t("common.count")}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <SummaryCardView
            value={
              query.isLoading ? (
                <Skeleton />
              ) : (
                query.data?.totalArea?.toLocaleString() || "--"
              )
            }
            title={
              query.isLoading ? (
                <Skeleton />
              ) : (
                query.data?.totalAreaUom || t("common.area")
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
