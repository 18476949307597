import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";
import HerdSummaryBarChart from "./HerdSummaryBarChart";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";
import { useOrgState } from "providers";

export default function AssetMapThemes({ assetType }: { assetType: string }) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const assetsMapThemesQuery = useInventoryMapThemes(
    org,
    assetType ? `assets/${assetType}` : "",
    season?.id
  );
  const title =
    assetType === "animal"
      ? t("common.breed")
      : assetType === "plant"
      ? t("common.crop")
      : assetType === "fixed"
      ? t("common.type")
      : "";

  return (
    <Grid container mx={2}>
      <Grid sm={4}>
        {assetsMapThemesQuery?.data?.raw &&
        assetsMapThemesQuery?.data?.raw.length ? (
          <MapThemeItem
            id={assetType}
            title={title}
            // title={themeData.title}
            isActive
            data={assetsMapThemesQuery?.data?.raw}
            showActiveBorder={false}
          />
        ) : (
          <MapThemeItem
            id="skeleton"
            title="--"
            showTooltip={false}
            data={[{ name: "--", percent: 1 }]}
          />
        )}
      </Grid>
      <Grid sm={8}>
        {assetType === "animal" ? (
          <>
            <h6 className="text-center m-0 ellipsis-overflow">
              {t("inventory.assets.herdSummary")}
            </h6>
            <HerdSummaryBarChart data={assetsMapThemesQuery?.data?.raw} />
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
