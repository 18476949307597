import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import Button from "@mui/material/Button";

export default function YieldFilterForm({
  cropTypes,
  data,
  onSubmit,
  onDelete,
  editId,
  onCancel,
}) {
  const cancelLinkRef = useRef();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const [dataState, setDataState] = useState({
    ...data,
  });
  const [isValidated, setIsValidated] = useState();

  useEffect(() => {
    setDataState({
      ...data,
    });
  }, [data]);

  return (
    <Form validated={isValidated}>
      <Form.Row>
        <Form.Label
          className="d-flex flex-grow-1 pb-2"
          style={{ borderBottom: "solid 1px" }}
        >
          <div className="flex-grow-1" style={{ whiteSpace: "nowrap" }}>
            Crop Type
          </div>
          <div style={{ width: "200px", maxWidth: "350px" }}>
            <Form.Control
              required
              value={dataState.cropTypeId}
              as="select"
              disabled={data.cropTypeId ? "disabled" : ""}
              onChange={(e) => {
                setDataState({
                  ...dataState,
                  ...{ cropTypeId: e.target.value },
                });
              }}
            >
              <option value="">Select...</option>
              {cropTypes?.map((ct) => (
                <option key={ct.id} value={ct.id} disabled={ct.disabled}>
                  {ct.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please choose a crop type.
            </Form.Control.Feedback>
          </div>
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Label
          className="d-flex flex-grow-1 pb-2"
          style={{ borderBottom: "solid 1px" }}
        >
          <div className="flex-grow-1" style={{ whiteSpace: "nowrap" }}>
            Min/Max Yield ({dataState.yieldUom})
          </div>
          <Form.Control
            value={dataState.yieldMin || ""}
            onChange={(e) => {
              setDataState({
                ...dataState,
                ...{ yieldMin: Number(e.target.value) },
              });
            }}
            type="number"
            className="mr-3"
            placeholder="0"
            style={{ maxWidth: "100px" }}
          />
          -
          <Form.Control
            value={dataState.yieldMax || ""}
            onChange={(e) => {
              setDataState({
                ...dataState,
                ...{ yieldMax: Number(e.target.value) },
              });
            }}
            type="number"
            className="ml-3"
            placeholder="100"
            style={{ maxWidth: "100px" }}
          />
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Label
          className="d-flex flex-grow-1 pb-2"
          style={{ borderBottom: "solid 1px" }}
        >
          <div className="flex-grow-1" style={{ whiteSpace: "nowrap" }}>
            Min/Max Speed ({dataState.speedUom})
          </div>
          <Form.Control
            value={dataState.speedMin || ""}
            onChange={(e) => {
              setDataState({
                ...dataState,
                ...{ speedMin: Number(e.target.value) },
              });
            }}
            type="number"
            className="mr-3"
            placeholder="0"
            style={{ maxWidth: "100px" }}
          />
          -
          <Form.Control
            value={dataState.speedMax || ""}
            onChange={(e) => {
              setDataState({
                ...dataState,
                ...{ speedMax: Number(e.target.value) },
              });
            }}
            type="number"
            className="ml-3"
            placeholder="100"
            style={{ maxWidth: "100px" }}
          />
        </Form.Label>
      </Form.Row>
      <Form.Row>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setIsValidated(true);
            if (!dataState.cropTypeId) {
              return;
            }
            onSubmit({
              ...dataState,
              ...{
                speedUom: "mi/hr",
                yieldUom: "bu/ac",
                yieldUomId: "bu1ac-1",
                speedUomId: "mi1hr-1",
              },
            });
          }}
        >
          {t("common.save")}
        </Button>
        <Button
          ref={cancelLinkRef}
          href={`${rootUrl}/settings`}
          color="secondary"
          variant="outlined"
          className="ml-1"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </Button>
        {editId ? (
          <Button
            className="ml-auto"
            color="error"
            variant="outlined"
            onClick={() => {
              onDelete(dataState);
            }}
          >
            {t("common.delete")}
          </Button>
        ) : null}
      </Form.Row>
    </Form>
  );
}

YieldFilterForm.defaultProps = {
  data: {},
  cropTypes: null,
  editId: null,
  onCancel: () => {},
};

YieldFilterForm.propTypes = {
  data: PropTypes.shape({
    cropTypeId: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  cropTypes: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  editId: PropTypes.string,
  onCancel: PropTypes.func,
};
