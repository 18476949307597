import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import "./settings.scss";
import { MainLayout, PageLayout, PageColumn } from "../MainLayout";
import DataFilters from "./DataFilters";
import DataConnections from "./DataConnections";
import PrivacyAndTOS from "views/PrivacyAndTOS";
import { useState } from "react";
import pkgjson from "../../../package.json";
import { useAccountProfile } from "api/useAccountProfile";
import { useOrgState } from "providers";
import { ThemeSwitch } from "lib/theme";
import {
  useSettingsState,
  useSettingsDispatch,
} from "providers/SettingsProvider";
import Autocomplete from "lib/Select/Autocomplete";
// eslint-disable-next-line react/prop-types
function ProfileItem({ label, value }) {
  return (
    <div className="d-flex">
      <dt
        style={{ textTransform: "uppercase", fontWeight: "normal" }}
        className="mr-2"
      >
        {label}:
      </dt>
      <dd className="d-flex align-items-center">{value || "--"}</dd>
    </div>
  );
}

export default function SettingsView() {
  const { user } = useAuth0();
  const { org } = useOrgState();
  // const muiTheme = useTheme();
  const { theme, language, /* unitSystem, */ languages } = useSettingsState();
  const saveSettings = useSettingsDispatch();
  const { t } = useTranslation();
  const [showVersion, setShowVersion] = useState();
  const accountProfileQuery = useAccountProfile(org);

  return (
    <MainLayout className="settings-container">
      <h2
        onClick={(e) => {
          if (e.ctrlKey) {
            setShowVersion(!showVersion);
          }
        }}
      >
        {t("settings.title")}
      </h2>
      <PageLayout>
        <PageColumn style={null} xl={5} className="mb-3 mb-xl-0 overflow-auto">
          <Stack sx={{ height: "100%" }} spacing={2}>
            <Paper sx={{ p: 3, display: "flex", flexDirection: "column" }}>
              <Typography component={"h3"} variant="h6">
                {t("settings.profile")}
              </Typography>
              <div className="d-flex flex-wrap-reverse overflow-auto">
                <div className="flex-grow-1">
                  <dl className="d-flex flex-column mb-0">
                    {showVersion ? (
                      <div className="p-2 border rounded mb-2">
                        <ProfileItem
                          label={"Version"}
                          value={pkgjson.version}
                        />
                      </div>
                    ) : null}

                    <ProfileItem label={t("common.name")} value={user?.name} />
                    <ProfileItem
                      label={t("settings.email")}
                      value={user?.email}
                    />
                    <ProfileItem
                      label={t("user.role")}
                      value={accountProfileQuery.data?.role}
                    />
                    <ProfileItem
                      label={t("account.license")}
                      value={accountProfileQuery.data?.license}
                    />
                  </dl>
                </div>
                <div style={{ width: "8rem" }}>
                  <img
                    // referrerPolicy to fix 403 error on localhost
                    referrerPolicy="no-referrer"
                    alt="Profile image"
                    src={user.picture}
                    className="rounded-circle w-100"
                  />
                </div>
              </div>
              <Grid
                container
                className="d-flex flex-column-reverse flex-md-row"
              >
                <Grid md={6}>
                  <PrivacyAndTOS className="small m-0 mt-3" linksOnly />
                </Grid>
                {accountProfileQuery.data?.accountUrl ? (
                  <Grid md={6} className="d-flex">
                    <a
                      className={`${
                        theme === "dark" ? "text-warning " : ""
                      }ml-md-auto mt-auto`}
                      href={accountProfileQuery.data?.accountUrl}
                    >
                      {t("settings.manageAccount")}
                    </a>
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
            <Paper sx={{ p: 3 }} style={{ minHeight: "170px" }}>
              <Typography component={"h3"} variant="h6">
                {t("settings.preferences")}
              </Typography>
              <Autocomplete
                label={t("settings.language")}
                value={languages.find((l) => l.value === language) ?? ""}
                id="language-select"
                options={languages}
                onChange={(_e, item) => {
                  saveSettings({
                    language: item.value,
                  });
                }}
              />
              <ThemeSwitch
                sx={{ mt: 2 }}
                checked={theme === "dark"}
                onChange={(e) => {
                  saveSettings({
                    theme: !e.target.checked ? "light" : "dark",
                  });
                }}
              />
            </Paper>
            <Paper sx={{ p: 3, flexGrow: 1 }}>
              <Route path="/:org/:season/settings/:filters?/:addOrEdit?/:editId?">
                <Typography component={"h3"} variant="h6">
                  {t("settings.yieldDataFilters")}
                </Typography>
                <DataFilters />
              </Route>
            </Paper>
          </Stack>
        </PageColumn>
        <PageColumn style={null} xl={7}>
          <DataConnections />
        </PageColumn>
      </PageLayout>
    </MainLayout>
  );
}
