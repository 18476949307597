import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import Skeleton from "@mui/material/Skeleton";
import SummaryCardView from "components/SummaryCardView";
import { useConservationSummary } from "api/conservation/useConservationSummary";
import { useOrgState } from "providers";

export default function ConservationSummaryView() {
  const { org, season } = useOrgState();
  const summaryQ = useConservationSummary({ org, seasonId: season?.id });
  const { t } = useTranslation();
  const summary = summaryQ?.data;
  const count =
    summary?.aoiCount || summary?.aoiCount === 0 ? summary?.aoiCount : "--";
  const area =
    summary?.totalArea || summary?.totalArea === 0 ? summary?.totalArea : "--";

  return (
    <>
      <Grid container spacing={2} sx={{ m: 2 }}>
        <Grid xs={6}>
          <SummaryCardView
            value={summaryQ.isLoading ? <Skeleton /> : count?.toLocaleString()}
            title={
              summaryQ.isLoading ? (
                <Skeleton />
              ) : (
                `${t("common.carbon")} ${t("common.areas")}`
              )
            }
          />
        </Grid>
        <Grid xs={6}>
          <SummaryCardView
            value={summaryQ.isLoading ? <Skeleton /> : area?.toLocaleString()}
            title={
              summaryQ.isLoading ? (
                <Skeleton />
              ) : (
                `${t("common.carbon")} ${summary?.totalAreaUom || ""}`
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
