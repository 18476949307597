import React, { Component, ErrorInfo, ReactNode } from "react";
import Button from "@mui/material/Button";

interface Props {
  fallback?: ReactNode;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        this.props.fallback ?? (
          <div className="d-flex flex-column align-items-center mt-5">
            <h2>Something unexpected happened</h2>
            <span>Please try again or contact an administrator.</span>
            <Button
              variant="outlined"
              sx={{ mt: 3 }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Try again
            </Button>
          </div>
        )
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
