import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IProduct } from "types";
import { sortObjArr } from "lib";
import { useCropTypes } from "api/lookups/useCropTypes";

export function useProductsBySeason({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const cropTypesQ = useCropTypes(org);

  const getProducts = useCallback(async () => {
    const res = await axios.get<IProduct[]>(
      `/${org}/products/season/${seasonId}`
    );
    res.data.forEach((d) => {
      d.value = `${d.id}:${d.cropTypeId}`;
      if (d.isSeed) {
        const i = cropTypesQ.data.find((ct) => ct.id === d.cropTypeId);
        d.cropType = i?.name;
      }
    });
    sortObjArr(res.data, "name");
    return res.data;
  }, [cropTypesQ.data, org, seasonId]);

  return useQuery({
    queryKey: ["organization", org, "products", seasonId],
    queryFn: getProducts,
    enabled: !!org && !!seasonId && !!cropTypesQ.data,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
