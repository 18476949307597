import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "components/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

export default function DeleteButton({
  handleDelete,
  isLoading,
  ...rest
}: {
  handleDelete: () => void;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const nodeRef = useRef();
  return (
    <>
      <span className="ml-auto" ref={nodeRef}>
        <LoadingButton
          isLoading={isLoading}
          className="ml-1"
          variant="outlined"
          color="error"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          {...rest}
        >
          <div className="d-flex align-items-center">{t("common.delete")}</div>
        </LoadingButton>
      </span>
      <Popover
        open={!!anchorEl}
        elevation={12}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { p: 3 },
          },
        }}
      >
        <Box>
          <Typography variant="h6">{t("common.areYouSure")}</Typography>
          <Alert severity="warning">
            <Typography fontWeight={"bold"}>
              *{t("common.cannotUndo")}
            </Typography>
          </Alert>
        </Box>
        <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
          <Button
            sx={{ ml: "auto" }}
            variant="contained"
            onClick={() => {
              setAnchorEl(null);
            }}
            color="primary"
          >
            {t("common.no")}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setAnchorEl(null);
              handleDelete();
            }}
          >
            {t("common.yes")}
          </Button>
        </Box>
      </Popover>
    </>
  );
}
