import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch, useInventoryState } from "providers";
import { GeoDataTable } from "components";
import { useSubfieldColumns } from "./useSubfieldColumns";
import { IRXSubfield, ISubfield } from "types";
import {
  useSubfieldsGeodata,
  useSubfieldsGeodataExport,
} from "api/useSubfieldsGeodata";
import Autocomplete from "lib/Select/Autocomplete";
import InventoryCollapseAddButtons from "components/InventoryCollapseAddButtons";
import { EditIconButton } from "components/EditIconButton";
import { useSubfields } from "api/useSubfields";
import { useSubfieldTypes } from "api/subfields/useSubfieldTypes";

export default function SubfieldsListView({
  toggled,
  onToggle,
}: {
  toggled: boolean;
  onToggle: () => void;
}) {
  const { SubfieldsNonRxCols, SubfieldsRxCols } = useSubfieldColumns();
  const { t } = useTranslation();
  const { subfieldType } = useParams<{ subfieldType: string }>();
  const { season, org, rootUrl } = useOrgState();
  const subfieldsQuery = useSubfields(org, season?.id, subfieldType);
  const {
    fitInventoryBounds,
    highlightLayerFeatures,
    setInventoryFtrs,
  } = useInventoryDispatch();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const downloadsRef = useRef<string[]>([]);
  const downloadErrorsRef = useRef<string[]>([]);
  // force component render
  const [toRender, renderView] = useState<boolean>(false);
  const [fieldAndGroup, setFieldAndGroup] = useState({
    fieldId: "",
    groupId: "",
  });
  // boolean to determine if row was clicked
  // to prevent zooming when query data updates due to subtype change
  const fitBoundsRef = useRef(false);
  const {
    exportData,
    error,
    isError,
    isLoading: isExporting,
  } = useSubfieldsGeodataExport({
    org,
    type: subfieldType,
    onSettled: (res, err, vars) => {
      const { filename } = vars;
      if (err) {
        downloadErrorsRef.current = downloadErrorsRef.current.concat([
          filename,
        ]);
      }
      // remove filename from array after it's settled
      const exists = downloadsRef.current.find((d) => d === filename);
      const idx = exists ? downloadsRef.current.indexOf(exists) : -1;
      if (idx >= 0) {
        const newDownloads = downloadsRef.current.filter((d) => d !== filename);
        downloadsRef.current = newDownloads;
      }
    },
  });

  function getPopover(json: Record<string, unknown>) {
    const keys = Object.keys(json) as [keyof IRXSubfield];
    return (
      <dl style={{ maxHeight: "400px", overflow: "auto" }}>
        {keys.map((k) => (
          <div key={k}>
            <dt style={{ display: "inline" }}>{k}: </dt>
            <dd style={{ display: "inline" }}>{json[k]}</dd>
            <hr style={{ marginTop: 0, marginBottom: "0.5rem" }} />
          </div>
        ))}
      </dl>
    );
  }

  function getCols(subfieldType: string) {
    const editCol = {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(
        row: (ISubfield | IRXSubfield) & { data?: Record<string, unknown> }
      ) {
        return (
          <>
            {subfieldType === "rx" && row.data ? (
              <Tooltip title={getPopover(row.data)}>
                <IconButton
                  size="small"
                  aria-label={t("common.details")}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            <EditIconButton
              href={`${rootUrl}/inventory/subfields/${subfieldType}/${row.id}/edit`}
              size="small"
            />
          </>
        );
      },
    } as {
      Header: string;
      id?: string;
      isSortable?: boolean;
      accessor?: ((row: ISubfield & IRXSubfield) => void) | string;
    };
    const downloadHeader = {
      Header: "",
      isSortable: false,
      aggregateValue: (vals: unknown, row: { original: ISubfield }) => {
        return {
          fieldId: row?.original?.fieldId,
          subfieldGroupId: row?.original?.subfieldGroupId,
          field: row?.original?.field,
          subfieldGroup: row?.original?.subfieldGroup,
          farm: row?.original?.farm,
        };
      },
      aggregate: (
        leafValues: {
          fieldId: string;
          farm: string;
          subfieldGroupId: string;
          field: string;
          subfieldGroup: string;
        }[]
      ) => {
        if (!leafValues.length) {
          return;
        }
        const { field, farm, subfieldGroup, fieldId, subfieldGroupId } =
          leafValues[0] ?? {};
        const filename = `${field}${farm ? `(${farm})` : ""}-${subfieldGroup}`;
        const errMsg = isError ? error?.message : "";
        const isErrored = downloadErrorsRef.current.find((e) => e === filename);
        const isDownloading =
          !isErrored &&
          downloadsRef.current.find((d) => d === filename) &&
          isExporting;
        return (
          <>
            <IconButton
              size="small"
              disabled={isDownloading}
              sx={{ bgcolor: errMsg ? "error.main" : "" }}
              role={errMsg ? "alert" : ""}
              aria-label={
                isDownloading
                  ? t("exportingSubfield")
                  : isErrored
                  ? errMsg
                  : t("common.download")
              }
              title={!errMsg ? t("common.download") : errMsg}
              onClick={async (e) => {
                e.stopPropagation();
                if (!fieldId || !subfieldGroupId) {
                  return;
                }
                if (isErrored) {
                  const exists = downloadErrorsRef.current.find(
                    (d) => d === filename
                  );
                  const idx = exists
                    ? downloadErrorsRef.current.indexOf(exists)
                    : -1;
                  if (idx >= 0) {
                    const newErrors = downloadErrorsRef.current.filter(
                      (d) => d !== filename
                    );
                    downloadErrorsRef.current = newErrors;
                  }
                  // for re-render to update button state
                  renderView(!toRender);
                  return;
                }
                if (!downloadsRef.current.includes(filename)) {
                  downloadsRef.current.push(filename);
                }
                exportData({
                  fieldId,
                  groupId: subfieldGroupId,
                  filename,
                });
              }}
            >
              {isDownloading ? (
                <CircularProgress size={20} />
              ) : errMsg ? (
                <InfoOutlinedIcon />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
          </>
        );
      },
      id: "download-geojson",
    };

    if (subfieldType === "rx") {
      return [editCol]
        .concat(SubfieldsRxCols)
        .concat([downloadHeader, { Header: "", accessor: "farm" }]);
    }
    return [editCol]
      .concat(SubfieldsNonRxCols)
      .concat([downloadHeader, { Header: "", accessor: "farm" }]);
  }

  const query = useSubfieldsGeodata({
    org,
    type: subfieldType,
    seasonId: season?.id,
    ...fieldAndGroup,
  });

  const subfieldTypesQ = useSubfieldTypes(org);
  const currSubfieldTypeData = subfieldTypesQ.data?.find(
    (st) => st.nameEn.toLowerCase() === subfieldType
  );

  useEffect(() => {
    setFieldAndGroup({
      fieldId: "",
      groupId: "",
    });
    return () => {
      fitBoundsRef.current = false;
    };
  }, [subfieldType]);

  useEffect(() => {
    const d = query.data ? { ...query.data } : null;
    if (d && fitBoundsRef.current) {
      fitBoundsRef.current = false;
      fitInventoryBounds({ geojson: d });
    }
    setInventoryFtrs(d);
  }, [fitInventoryBounds, query.data, setInventoryFtrs]);

  return (
    <Box sx={{ p: 3 }}>
      <div className="d-flex align-items-center pb-2">
        <Typography
          sx={{ mr: 2, display: "flex", flexDirection: "column" }}
          component={"h3"}
          variant="h5"
        >
          {t("inventory.subfields.subfieldList")}
          <Typography sx={{ fontStyle: "italic" }} className="">
            {currSubfieldTypeData?.name}
          </Typography>
        </Typography>

        <InventoryCollapseAddButtons
          toggleExpanded={onToggle}
          expanded={toggled}
          addUrl={`${rootUrl}/inventory/subfields/${encodeURIComponent(
            subfieldType
          )}/add`}
          hideAdd={!subfieldType}
        />
      </div>
      {subfieldType ? (
        <div
        // style={{ minHeight: "500px" }}
        // className="p-3 flex-grow-1 overflow-auto"
        >
          <GeoDataTable
            isLoading={subfieldsQuery.isLoading}
            hiddenColumns={["farm"]}
            singleGroupExpand
            preventGroupedRowColspanExpand
            components={{
              HeaderLeft: (
                <p className="small m-0">
                  {subfieldsQuery.data && subfieldsQuery.data.length
                    ? t("common.selectToZoom")
                    : ""}
                </p>
              ),
            }}
            paging={subfieldsQuery.data?.length > 100}
            map={inventoryMap}
            highlightByIds={highlightLayerFeatures}
            featureCollection={query.data}
            ftrsClicked={ftrsClicked}
            columns={getCols(subfieldType)}
            filterInputProps={{ style: { maxWidth: "unset" } }}
            data={subfieldsQuery.data}
            groupBy={["subfieldGroupKey"]}
            onRowExpand={async (row: {
              leafRows?: { original: IRXSubfield }[];
            }) => {
              if (row.leafRows.length) {
                const { fieldId, subfieldGroupId } = row.leafRows[0].original;
                if (fieldId) {
                  fitBoundsRef.current = true;
                  setFieldAndGroup({
                    fieldId,
                    groupId: subfieldGroupId,
                  });
                }
              }
            }}
          />
        </div>
      ) : null}

      {!subfieldType ? (
        <>
          <Autocomplete
            label={t("common.type")}
            options={subfieldTypesQ.data}
            getOptionLabel={(o: { nameEn: string; name: string }) => o.name}
            renderOption={(props, option) => (
              <li style={{ padding: 0 }} {...props} key={option.nameEn}>
                <Link
                  underline="hover"
                  sx={{
                    "&:hover": { color: "inherit" },
                    color: "inherit",
                    p: 1,
                    width: "100%",
                  }}
                  href={`${rootUrl}/inventory/subfields/${encodeURIComponent(
                    option?.nameEn?.toLowerCase()
                  )}`}
                >{`${option.nameEn}`}</Link>
              </li>
            )}
          />
        </>
      ) : null}
    </Box>
  );
}
