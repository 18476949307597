import React from "react";
import { useOrgState } from "providers/OrgProvider";
import { useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import ObservationPhotosStack from "./ObservationPhotosStack";
import { IUrlParams } from "types";
import { useObservationById } from "api/observations/useObservationById";

export function ObservationPhotos() {
  const { rootUrl } = useOrgState();
  const { observationId, org } = useParams<IUrlParams>();
  const observationQ = useObservationById({ org, observationId });
  const { t } = useTranslation();
  const template = observationQ?.data?.properties?.template;

  return (
    <>
      <Breadcrumbs sx={{ textTransform: "uppercase", mb: 2 }}>
        <Link underline="hover" href={`${rootUrl}/inventory/scouting`}>
          {t("common.scouting")}
        </Link>
        <Link
          underline="hover"
          href={`${rootUrl}/inventory/scouting${
            template ? `?template=${template}` : ""
          }`}
        >
          {observationQ.isLoading ? <Skeleton width={125} /> : template ?? "--"}
        </Link>
        <Link
          underline="hover"
          href={`${rootUrl}/inventory/scouting${
            template ? `?template=${template}` : ""
          }${
            observationQ?.data?.properties?.field
              ? `${template ? "&" : "?"}fields=${
                  observationQ?.data?.properties?.field
                }`
              : ""
          }`}
        >
          {observationQ.isLoading ? (
            <Skeleton width={100} />
          ) : (
            observationQ?.data?.properties.field ?? ""
          )}
        </Link>
        <Typography>
          {observationQ.isLoading ? (
            <Skeleton width={200} />
          ) : observationQ?.data?.properties?.observedOnUtc ? (
            new Date(
              observationQ?.data?.properties?.observedOnUtc
            ).toLocaleString()
          ) : (
            "--"
          )}
        </Typography>
      </Breadcrumbs>

      <div className="d-flex flex-column">
        <span
          style={{
            textTransform: "uppercase",
            fontSize: "0.85rem",
            textDecoration: "underline",
          }}
        >
          {t("common.notes")}
        </span>
        <span style={{ textTransform: "uppercase" }}>
          {observationQ.isLoading ? (
            <Skeleton width={150} />
          ) : (
            observationQ?.data?.properties?.notes ?? <>&nbsp;</>
          )}
        </span>
      </div>
      <hr className="text-light" />
      <ObservationPhotosStack />
    </>
  );
}
