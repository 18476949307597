import React, { useState, useEffect, useCallback } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import Paper from "@mui/material/Paper";
import SummaryView from "./SummaryView";
import OperationsListView from "./ListView";
import OperationMapThemes from "./MapThemes";
import CardStack from "../../CardStack";
import OperationDetails from "./OperationDetails";
import { OperationType } from "types/OperationType";
import CreateOrEditOperation from "./CreateOrEditOperation";

export function OperationsView() {
  const location = useLocation();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { setLegendData } = useInventoryDispatch();
  const { type, itemId } = useParams<{ type: OperationType; itemId: string }>();
  const addView = location.pathname.includes("/add");
  const [expanded, setExpanded] = useState<boolean>();
  const [legendProp, setLegendProp] = useState<string>();
  const history = useHistory();

  const {
    setColSizes,
    setTitle,
    setLabelProp,
    toggleFieldLabels,
  } = useInventoryDispatch();

  useEffect(() => {
    setTitle(t("inventory.operations.title"));
    setLabelProp("product");
    setColSizes([7, 5]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  useEffect(() => {
    toggleFieldLabels(true);
    return () => {
      toggleFieldLabels(false);
    };
  }, [toggleFieldLabels]);

  const getActiveLegendData = useCallback(() => {
    switch (legendProp) {
      case "product": {
        return {
          title: t("common.product"),
          colorProp: "productColor",
          dataProp: "product",
        };
      }
      case "productType": {
        return {
          title: t("common.type"),
          colorProp: "productTypeColor",
          dataProp: "productType",
        };
      }
      case "crop": {
        return {
          title: t("common.crop"),
          colorProp: "cropColor",
          dataProp: "crop",
        };
      }
      case "tilledType": {
        return {
          title: t("common.type"),
          colorProp: "tilledTypeColor",
          dataProp: "tilledType",
        };
      }
      default: {
        return null;
      }
    }
  }, [legendProp, t]);

  const updateLegend = useCallback(() => {
    const themeData = getActiveLegendData();
    setLegendData(themeData);
  }, [setLegendData, getActiveLegendData]);

  // effect for updating legend
  useEffect(() => {
    updateLegend();
  }, [legendProp, updateLegend]);

  // effect for setting themeProp
  useEffect(() => {
    const tLower = type?.toLowerCase();
    let legendP = "product";
    if (tLower === "harvested") {
      legendP = "crop";
    }
    if (tLower === "tilled") {
      legendP = "tilledType";
    }
    setLegendProp(legendP);
  }, [type]);

  return (
    <>
      <CardStack
        // bottomMinHeight="400px"
        // topHeight={"300px"}
        expanded={Boolean(itemId || addView || expanded)}
        topChildren={
          <>
            <Paper sx={{ p: 3 }}>
              <SummaryView
                activeType={type?.toLowerCase()}
                onSelect={(d) => {
                  history.push(`${rootUrl}/inventory/operations/${d}`);
                }}
              />
            </Paper>
            <Paper className="flex-grow-1">
              <OperationMapThemes
                operationType={type?.toLowerCase()}
                // themeData={currentThemeRollup}
                activeType={legendProp}
                onThemeChange={(themeProp) => {
                  setLegendProp(themeProp);
                }}
              />
            </Paper>
          </>
        }
      >
        <Paper sx={{ height: "100%", overflow: "auto" }}>
          <Switch>
            <Route path="/:org/:season/inventory/operations/:type" exact>
              <OperationsListView
                activeType={type}
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </Route>
            <Route path="/:org/:season/inventory/operations/:type/add">
              <CreateOrEditOperation />
            </Route>
            <Route path="/:org/:season/inventory/operations/:type/items/:itemId/edit">
              <CreateOrEditOperation />
            </Route>
            <Route path="/:org/:season/inventory/operations/:type/items/:itemId">
              <OperationDetails />
            </Route>
          </Switch>
        </Paper>
      </CardStack>
    </>
  );
}
