import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Route, Switch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import OperationEventsTable from "./OperationEvents/OperationEventsTable";
import OperationEventEdit from "./OperationEvents/OperationEventEdit";
import OperationBreadcrumbs from "./OperationBreadcrumbs";
import { EditIconButton } from "components/EditIconButton";
import { IUrlParams } from "types";
import BaseCardView from "components/BaseCardView";
import SummaryCardTitle from "components/SummaryCardTitle";
import SummaryCardValue from "components/SummaryCardValue";
import { OperationHarvested } from "api/operations/types/OperationHarvested";

export default function HarvestedOperationDetails() {
  const { t } = useTranslation();
  const { rootUrl, org, season } = useOrgState();
  const { itemId } = useParams<IUrlParams>();
  const geodataQ = useOperationsGeodataById<OperationHarvested>({
    id: itemId,
    org,
    seasonId: season?.id,
    type: "harvested",
  });
  const details = geodataQ.data?.features[0].properties;
  const operationId = details?.operationId;
  return (
    <Stack spacing={2} sx={{ height: "100%" }}>
      <Switch>
        <Route
          exact
          path={`/:org/:season/inventory/operations/:type/items/:itemId`}
        >
          <Stack spacing={2} sx={{ p: 3, flex: 1, overflow: "auto" }}>
            <Stack direction={"row"}>
              <OperationBreadcrumbs type="harvested" />
              {itemId ? (
                <EditIconButton
                  sx={{ ml: "auto" }}
                  href={`${rootUrl}/inventory/operations/harvested/items/${itemId}/edit`}
                />
              ) : null}
            </Stack>
            <Paper
              elevation={4}
              sx={{ bgcolor: "background.paper", p: 3, width: "100%" }}
            >
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    mb: 2,
                    textTransform: "uppercase",
                    fontSize: 14,
                  }}
                >
                  {t("inventory.operations.operationDetails")}
                </Typography>
                <Typography
                  sx={{
                    ml: "auto",
                  }}
                >
                  {`${
                    details?.beginDateUtc
                      ? new Date(details.beginDateUtc).toLocaleDateString()
                      : "--"
                  } - ${
                    details?.endDateUtc
                      ? new Date(details.endDateUtc).toLocaleDateString()
                      : "--"
                  }`}
                </Typography>
              </Stack>
              <Stack direction={"row"} gap={2} flexWrap="wrap">
                <BaseCardView
                  variant="outlined"
                  sx={{
                    bgcolor: "background.paper",
                  }}
                >
                  <SummaryCardTitle>{t("common.cropType")}</SummaryCardTitle>
                  <SummaryCardValue>{details?.crop}</SummaryCardValue>
                </BaseCardView>
                {details?.averageYield || details?.averageYield === 0 ? (
                  <BaseCardView
                    variant="outlined"
                    sx={{ bgcolor: "background.paper" }}
                  >
                    <SummaryCardTitle>
                      {t("common.averageYield")}
                    </SummaryCardTitle>
                    <SummaryCardValue>
                      {details
                        ? `${details.averageYield} ${details.averageYieldUom}`
                        : "--"}
                    </SummaryCardValue>
                  </BaseCardView>
                ) : null}
                <BaseCardView
                  variant="outlined"
                  sx={{ bgcolor: "background.paper" }}
                >
                  <SummaryCardTitle>
                    {t("inventory.operations.averagePercentMoisture")}
                  </SummaryCardTitle>
                  <SummaryCardValue>
                    {details ? `${details.averagePercentMoisture}` : "--"}
                  </SummaryCardValue>
                </BaseCardView>
                <BaseCardView
                  variant="outlined"
                  sx={{ bgcolor: "background.paper" }}
                >
                  <SummaryCardTitle>{t("common.totalMass")}</SummaryCardTitle>
                  <SummaryCardValue>
                    {details
                      ? `${details.totalMass} ${details.totalMassUom ?? ""}`
                      : "--"}
                  </SummaryCardValue>
                </BaseCardView>
                {details?.count || details?.count === 0 ? (
                  <BaseCardView
                    variant="outlined"
                    sx={{ bgcolor: "background.paper" }}
                  >
                    <SummaryCardTitle>{t("common.count")}</SummaryCardTitle>
                    <SummaryCardValue>
                      {details ? `${details.count}` : "--"}
                    </SummaryCardValue>
                  </BaseCardView>
                ) : null}
                <BaseCardView
                  variant="outlined"
                  sx={{ bgcolor: "background.paper" }}
                >
                  <Stack>
                    <SummaryCardTitle>{t("common.area")}</SummaryCardTitle>
                    <SummaryCardValue>
                      {details
                        ? `${details.area ?? "--"} ${details.areaUom ?? ""}`
                        : "--"}
                    </SummaryCardValue>
                  </Stack>
                </BaseCardView>
              </Stack>
            </Paper>
            <Paper
              elevation={4}
              sx={{ bgcolor: "background.paper", p: 3, width: "100%" }}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: 14,
                  }}
                >
                  {t("common.records")}
                </Typography>
                <IconButton
                  color="success"
                  sx={{ ml: "auto" }}
                  href={`${itemId}/records/add`}
                >
                  <AddOutlinedIcon />
                </IconButton>
              </Stack>
              <OperationEventsTable
                type={"harvested"}
                operationId={operationId}
              />
            </Paper>
          </Stack>
        </Route>
        <Route
          path={`/:org/:season/inventory/operations/:type/items/:itemId/records/add`}
        >
          <Stack spacing={2} sx={{ p: 3, flex: 1, overflow: "auto" }}>
            <OperationBreadcrumbs type="harvested" />
            <OperationEventEdit operationId={operationId} />
          </Stack>
        </Route>
        <Route
          path={`/:org/:season/inventory/operations/:type/items/:itemId/records/:eventId`}
        >
          <Stack spacing={2} sx={{ p: 3, flex: 1, overflow: "auto" }}>
            <OperationBreadcrumbs type="harvested" />
            <OperationEventEdit operationId={operationId} />
          </Stack>
        </Route>
      </Switch>
    </Stack>
  );
}
