import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";

export default function MapThemes({ themeType, onThemeTypeChange, themeData }) {
  const { t } = useTranslation();
  return (
    <Grid container mx={2}>
      <Grid sm={12}>
        {themeData && themeData.length ? (
          <MapThemeItem
            id="damage-type"
            title={t("common.type")}
            isActive={themeType === "damage-type"}
            onClick={() => {
              onThemeTypeChange("damage-type");
            }}
            data={themeData}
          />
        ) : null}
        {themeData && !themeData.length ? (
          <h6 className="text-center small mt-4">{t("common.noData")}</h6>
        ) : null}
        {!themeData ? (
          <MapThemeItem
            id="skeleton"
            title={t("common.type")}
            isActive={false}
            data={[{ percent: 100, name: "" }]}
            showTooltip={false}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
MapThemes.defaultProps = {
  themeType: "damage-type",
  themeData: null,
};
MapThemes.propTypes = {
  themeType: PropTypes.oneOf(["damage-type"]),
  onThemeTypeChange: PropTypes.func.isRequired,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
};
