import { FeatureCollection } from "geojson";
export * from "./utils";
export { default as ConfirmAlert } from "./ConfirmAlert";
export { default as LinearProgress } from "./LinearProgress";
export { ThemeProvider } from "./theme";
export { default as ConfirmationDialog } from "./ConfirmationDialog";
export function mergeProps(
  fc: FeatureCollection,
  themes: { id: string }[],
  list?: { id: string }[]
) {
  const newFc = { ...fc, features: [...fc.features] };
  newFc.features.forEach((ftr) => {
    const listItem = list?.find((li) => li.id === ftr.id);
    const themeItem = themes?.find((li) => li.id === ftr.id);
    if (themeItem) {
      Object.assign(ftr.properties, themeItem);
    }
    if (listItem) {
      Object.assign(ftr.properties, listItem);
    }
  });
  return newFc;
}
