import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Route, useParams, Redirect } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { DataTable } from "components";
import { IUrlParams } from "types";
import { useProductsBySeason } from "api/products/useProductsBySeason";
import useProductColumns from "components/Table/useProductColumns";

export function ProductsView() {
  const { rootUrl, org, season } = useOrgState();
  const productsQ = useProductsBySeason({ org, seasonId: season?.id });
  const { t } = useTranslation();
  const { type } = useParams<IUrlParams>();

  const { setColSizes, hideMap, showMap, setTitle } = useInventoryDispatch();

  useEffect(() => {
    setColSizes([12, 0]);
    setTitle(t("inventory.products.title"));
  }, [setColSizes, setTitle, t]);

  useEffect(() => {
    hideMap();
    return showMap;
  }, [hideMap, showMap]);
  const { seedColumns, applicationColumns } = useProductColumns();

  if (!type) {
    return <Redirect to={`${rootUrl}/inventory/products/seed`} />;
  }
  return (
    <Paper sx={{ height: "100%" }}>
      <TabContext value={type}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              sx={{
                ".MuiTabPanel-root": {
                  p: 0,
                },
                a: {
                  textDecoration: "none",
                },
              }}
              onChange={() => {}}
              aria-label="product type tabs"
            >
              <Tab
                label={t("inventory.products.seed.title")}
                value="seed"
                href={`${rootUrl}/inventory/products/seed`}
              />
              <Tab
                label={t("inventory.products.application.title")}
                href={`${rootUrl}/inventory/products/application`}
                value="application"
              />
            </TabList>
          </Box>
          <TabPanel value="seed" sx={{ height: "100%", overflow: "auto" }}>
            <Route path="/:org/:season/inventory/products/seed" exact>
              <DataTable
                isLoading={productsQ.isLoading}
                data={productsQ?.data?.filter((d) => {
                  return d.isSeed;
                })}
                columns={seedColumns}
              />
            </Route>
          </TabPanel>
          <TabPanel
            value="application"
            sx={{ height: "100%", overflow: "auto" }}
          >
            <Route path="/:org/:season/inventory/products/application" exact>
              <DataTable
                isLoading={productsQ.isLoading}
                data={productsQ?.data?.filter((d) => {
                  return !d.isSeed;
                })}
                columns={applicationColumns}
              />
            </Route>
          </TabPanel>
        </Box>
      </TabContext>
    </Paper>
  );
}
