import { useTranslation } from "react-i18next";
import AssetsOverviewChart from "./AssetOverviewChart";
import LinearProgress from "@mui/material/LinearProgress";
import { useAssetsSeasonSummary } from "api/assets/useAssetSummaries";
import { useOrgState } from "providers";

export default function AssetsOverview() {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const assetsBySeasonQuery = useAssetsSeasonSummary(org, season?.id);
  const data = assetsBySeasonQuery.data?.filter((d) => d.count);

  return (
    <>
      {assetsBySeasonQuery.isLoading ? <LinearProgress /> : null}
      <h3>
        {t("inventory.assets.title")} {t("inventory.title")}
      </h3>
      {!assetsBySeasonQuery.isLoading && data?.length ? (
        <AssetsOverviewChart data={data} />
      ) : null}
      {!assetsBySeasonQuery.isLoading && !data?.length ? (
        <p className="text-center">{t("common.noData")}</p>
      ) : null}
    </>
  );
}
