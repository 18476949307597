import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DatePicker } from "lib/DatePicker";
import Autocomplete from "lib/Select/Autocomplete";
import GeodataUpload from "views/InventoryView/GeodataUpload";
import { FeatureCollection } from "geojson";
import { useAssetsUpload } from "api/useAssetsUpload";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch, useInventoryState } from "providers";
import { GeoDataTable } from "components";
import { AlertCircle } from "react-feather";
import {
  parseFeatureCollectionPropKeys,
  setFeatureCollectionProps,
} from "app-utils";
import BaseForm from "components/Forms/BaseForm";
import { validateFeatureCounts } from "./asset-upload-utils";
import useOrgLookupRQ from "components/useOrgLookupRQ";
import { ICropType } from "types";
import { useCropTypes } from "api/lookups/useCropTypes";

export default function PlantAssetUpload({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const cropTypesQ = useCropTypes(org);
  const {
    fitInventoryBounds,
    highlightLayerFeatures,
    setInventoryFtrs,
  } = useInventoryDispatch();
  const { ftrsClicked, inventoryMap } = useInventoryState();
  const history = useHistory();
  const [parseError, setParseError] = useState("");
  const [parsedKeys, setParsedKeys] = useState<
    { value: string; label: string }[]
  >([]);
  const [uploadedFc, setUploadedFc] = useState<FeatureCollection>();
  const [cropTypeId, setCropTypeId] = useState("");
  const [assetStatusId, setAssetStatusId] = useState("");
  const [cidProp, setCidProp] = useState("");
  const [bornOnProp, setBornOnProp] = useState("");
  const [descriptionProp, setDescriptionProp] = useState("");
  const [useManualDateSelect, setUseManualDateSelect] = useState(false);
  const [useManualDescriptionInput, setUseManualDescriptionInput] = useState(
    false
  );
  const [manualDate, setManualDate] = useState<Date>();
  const [manualDescription, setManualDescription] = useState("");
  const { uploadAssets, reset: resetUpload, ...query } = useAssetsUpload({
    org,
    seasonId: season.id,
    type: "plant",
  });

  const { data: assetStatuses } = useOrgLookupRQ(
    `/${org}/lookups/assetstatuses`
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID *",
        accessor: "cid",
        Cell: ({ value }: { value: string | number }) => {
          const val = value || value === 0 ? `${value}` : null;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val ?? (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: `${t("inventory.assets.plantedDate")} *`,
        accessor: "bornOnUtc",
        Cell: ({ value }: { value: string }) => {
          const val = manualDate ? manualDate.toLocaleDateString() : value;
          return (
            <div className={!val ? "text-danger" : ""}>
              {val || (
                <>
                  <AlertCircle />
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: t("common.notes"),
        accessor: "description",
        Cell: ({ value }: { value: string }) => {
          return <div>{value ?? manualDescription}</div>;
        },
      },
    ],
    [manualDescription, manualDate, t]
  );
  const formattedFC = React.useMemo(() => {
    if (!uploadedFc) {
      return null;
    }
    const formatted = setFeatureCollectionProps(
      uploadedFc,
      {
        name: null,
        cropTypeId,
        assetStatusId,
        ...(manualDate
          ? {
              bornOnUtc: manualDate.toISOString(),
            }
          : {}),
        ...(manualDescription ? { description: manualDescription } : {}),
      },
      {
        description: descriptionProp,
        bornOnUtc: bornOnProp,
        cid: cidProp,
      }
    );
    return formatted;
  }, [
    assetStatusId,
    bornOnProp,
    cidProp,
    cropTypeId,
    descriptionProp,
    manualDescription,
    manualDate,
    uploadedFc,
  ]);
  const tableData = React.useMemo(
    () => formattedFC?.features.map((f) => f.properties) ?? [],
    [formattedFC?.features]
  );
  const { validCount, invalidCount } = validateFeatureCounts(formattedFC, [
    "bornOnUtc",
    "cid",
  ]);

  return (
    <>
      <BaseForm
        cancelHref="./"
        saveState={{
          isLoading: query.isLoading,
          isError: query.isError,
          errorMessage: query.error?.message,
        }}
        onSubmit={() => {
          resetUpload();
          if (!uploadedFc) {
            setParseError(t("common.requiredField"));
            return false;
          }
          if (invalidCount) {
            return false;
          }
          return uploadAssets(formattedFC, {
            onSuccess: () => {
              onSuccess();
              history.push("./");
            },
          });
        }}
      >
        <Stack spacing={2}>
          <Autocomplete
            loading={cropTypesQ.isLoading}
            label={`${t("common.crop")} *`}
            InputProps={{
              required: true,
            }}
            id="crop-select"
            options={cropTypesQ.data || []}
            value={cropTypesQ.data?.find((f) => f.id === cropTypeId) ?? null}
            onChange={(_e, item) => {
              const opt = item as ICropType;
              setCropTypeId(opt?.id);
              if (uploadedFc) {
                const ftrsWithColor = setFeatureCollectionProps(uploadedFc, {
                  color: opt?.color || "#eee",
                  crop: opt?.name ?? "??",
                });
                setInventoryFtrs(ftrsWithColor);
              }
            }}
            disableClearable={false}
          />

          <Autocomplete
            label={`${t("common.status")} *`}
            disableClearable={false}
            InputProps={{
              required: true,
            }}
            id="status-select"
            name="assetStatusId"
            options={assetStatuses}
            value={assetStatuses?.find((ao) => ao.value === assetStatusId)}
            onChange={(_e, item: { id: string }) => {
              setAssetStatusId(item?.id);
            }}
          />

          <div className="d-flex align-items-center mt-3">
            <GeodataUpload
              type="Point"
              onChange={(geojson) => {
                setParsedKeys([]);
                setParseError("");
                setUploadedFc(geojson);
                if (geojson) {
                  const cropType = cropTypesQ.data?.find(
                    (f) => f.value === cropTypeId
                  );
                  const ftrsWithColor = setFeatureCollectionProps(geojson, {
                    color: cropType?.color || "#eee",
                    crop: cropType?.name ?? "??",
                  });
                  setInventoryFtrs(ftrsWithColor);
                  fitInventoryBounds({ geojson });
                  const keys = parseFeatureCollectionPropKeys(geojson);
                  setParsedKeys(
                    keys.map((p) => ({
                      value: p,
                      label: p,
                    }))
                  );
                } else {
                  setInventoryFtrs(null);
                }
              }}
              onError={(e) => {
                setParseError(e);
              }}
            />
            <Typography color="info.main">GeoJSON</Typography>
          </div>
          {parseError ? (
            <Typography color="error.main">{parseError}</Typography>
          ) : null}

          {parsedKeys.length ? (
            <Stack spacing={2}>
              <Typography>{t("common.mapGeoJSONProps")}</Typography>
              <Autocomplete
                InputProps={{
                  required: true,
                }}
                label="ID *"
                disableClearable={false}
                value={parsedKeys?.find((p) => p.value === cidProp) ?? null}
                options={parsedKeys}
                onChange={(_e, item: { value: string }) => {
                  setCidProp(item?.value);
                }}
              />
              <Stack direction={"row"} spacing={2}>
                <Box sx={{ flex: 1 }}>
                  {useManualDateSelect ? (
                    <DatePicker
                      label={`${t("inventory.assets.plantedDate")}`}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          required: true,
                        },
                      }}
                      maxDate={new Date()}
                      value={manualDate}
                      onChange={(d: Date) => {
                        setManualDate(d);
                      }}
                    />
                  ) : (
                    <Autocomplete
                      label={`${t("inventory.assets.plantedDate")} *`}
                      InputProps={{ required: true }}
                      disableClearable={false}
                      value={
                        parsedKeys?.find((p) => p.value === bornOnProp) ?? null
                      }
                      options={parsedKeys}
                      onChange={(_e, item: { value: string }) => {
                        setBornOnProp(item?.value);
                      }}
                    />
                  )}
                </Box>
                <FormControlLabel
                  sx={{ textTransform: "uppercase" }}
                  control={
                    <Switch
                      checked={useManualDateSelect}
                      onChange={(e) => {
                        e.stopPropagation();
                        setUseManualDateSelect(e.currentTarget.checked);
                        if (!e.currentTarget.checked) {
                          setManualDate(null);
                        } else {
                          setBornOnProp("");
                        }
                      }}
                      inputProps={{
                        "aria-label": t("common.manualInput"),
                      }}
                    />
                  }
                  label={t("common.manualInput")}
                />
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Box sx={{ flex: 1 }}>
                  {useManualDescriptionInput ? (
                    <TextField
                      fullWidth
                      label={t("common.notes")}
                      multiline
                      inputProps={{
                        maxLength: 255,
                      }}
                      onChange={(e) => {
                        setManualDescription(e.target.value);
                      }}
                    />
                  ) : (
                    <Autocomplete
                      disableClearable={false}
                      value={
                        parsedKeys?.find((p) => p.value === descriptionProp) ??
                        null
                      }
                      options={parsedKeys}
                      onChange={(_e, item: { value: string }) => {
                        setDescriptionProp(item?.value);
                      }}
                      label={t("common.notes")}
                    />
                  )}
                </Box>
                <FormControlLabel
                  sx={{ textTransform: "uppercase" }}
                  control={
                    <Switch
                      checked={useManualDescriptionInput}
                      onChange={(e) => {
                        e.stopPropagation();
                        setUseManualDescriptionInput(e.currentTarget.checked);
                        if (!e.currentTarget.checked) {
                          setManualDescription("");
                        } else {
                          setDescriptionProp("");
                        }
                      }}
                      inputProps={{
                        "aria-label": t("common.manualInput"),
                      }}
                    />
                  }
                  label={t("common.manualInput")}
                />
              </Stack>
              {formattedFC ? (
                <Typography
                  variant="body2"
                  color={invalidCount ? "error.main" : "info.main"}
                >
                  {`${t("common.validFeatures")}: ${validCount} /
                  ${formattedFC.features.length}`}
                </Typography>
              ) : null}
            </Stack>
          ) : null}
        </Stack>
      </BaseForm>

      {formattedFC ? (
        <div
          className="inventory-list overflow-auto"
          style={{ minHeight: "250px" }}
        >
          <GeoDataTable
            isFilterable={false}
            highlightByIds={highlightLayerFeatures}
            ftrsClicked={ftrsClicked}
            map={inventoryMap}
            featureCollection={formattedFC}
            data={tableData}
            columns={columns}
          />
        </div>
      ) : null}
    </>
  );
}
